import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as DonkeyMobileLogo } from '../../assets/svg/ic_donkey_mobile_logo.svg'

interface Properties {
    isMobile: boolean
}

export const DonkeyBrandingView = ({ isMobile }: Properties) => {
    const { t: translation } = useTranslation()

    const mobile = isMobile ? 'flex' : 'none'
    const phablet = isMobile ? 'none' : 'flex'

    return (
        <Container display={{ mobile, phablet }}>
            <DonkeyLogoContainer>
                <DonkeyMobileLogo />
            </DonkeyLogoContainer>

            <Box ml={2} display="flex" flexDirection="column" flexGrow="1">
                <Typography variant="body1" fontSize="16px" fontWeight="bold">
                    {translation('made_by_donkey_mobile')}
                </Typography>

                <Typography variant="body1" fontSize="16px">
                    {translation('promote_giving_message')}{' '}
                    <PromoteGivingLink href="https://donkeymobile.app/totaaloplossing-voor-geven">
                        {translation('see_how_here')}
                    </PromoteGivingLink>
                </Typography>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    marginRight: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    marginLeft: theme.spacing(3),

    [theme.breakpoints.down('phablet')]: {
        marginTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
}))

const DonkeyLogoContainer = styled(Box)(() => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    height: '64px',
    width: '64px',
    backgroundColor: 'white',
    boxShadow: '4px 4px 8px 0 rgba(153, 153, 153, 0.08)',
    borderRadius: '16px',
}))

const PromoteGivingLink = styled('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    textDecoration: 'none',
}))
