import { Box, Link, LinkProps, styled, Typography } from '@mui/material'
import { LocalPdf } from '../forms/LocalPdf'
import { Pdf } from '../models/Pdf'
import { FileRepository } from '../files/FileRepository'
import { darkGrey, errorColor, grey_3 } from '../theme/Theme'
import { BYTES_PER_KB, BYTES_PER_MB, MAX_PDF_SIZE_BYTES } from '../common/Constants'
import { TranslationFn } from '../WithTranslations'
import { FilledCloseButtonWithWhiteCross } from './buttons/CloseButton'

interface Properties {
    hasWhiteBackground?: boolean
    hasExceeded?: boolean
    pdf?: Pdf
    uploadedPdf?: LocalPdf
    fileRepository: FileRepository
    translations: TranslationFn
    disableDeleteButton?: boolean
    onDeleteButtonClicked?: (pdf: Pdf | LocalPdf) => void
}

export const PdfView = (properties: Properties) => {
    const { pdf, uploadedPdf, translations, onDeleteButtonClicked } = properties

    const hasWhiteBackground = properties.hasWhiteBackground ?? false
    const hasExceeded = properties.hasExceeded ?? false
    const name = pdf?.name ?? uploadedPdf?.name
    const previewImageSrc =
        properties.fileRepository.getFileUrl(pdf?.imageKey) ?? uploadedPdf?.thumbnail.source

    const size = pdf?.size ?? uploadedPdf?.file.size ?? 0
    const sizeString =
        size >= 10000
            ? `${(size / BYTES_PER_MB).toFixed(2)} MB`
            : `${(size / BYTES_PER_KB).toFixed(2)} KB`

    const href = pdf ? properties.fileRepository.getFileUrl(pdf.key) : undefined

    if (!pdf && !uploadedPdf) {
        return <></>
    }

    return (
        <Container
            bgcolor={hasWhiteBackground ? 'white' : grey_3}
            hasExceeded={hasExceeded}
            href={href}
            target="_blank"
            rel="noreferrer"
        >
            <ImageView src={previewImageSrc} alt={name} />

            <Box flexGrow={1} display="flex" flexDirection="column" overflow="hidden">
                <PdfNameTextView variant="body1">{name}</PdfNameTextView>

                <Box display="flex" alignItems="center">
                    <SizeTextView variant="body2">{sizeString}</SizeTextView>

                    {hasExceeded && (
                        <ErrorTextView variant="body2" color={errorColor}>
                            {`${translations('maximum_of', [
                                MAX_PDF_SIZE_BYTES / BYTES_PER_MB,
                            ])} MB`}
                        </ErrorTextView>
                    )}
                </Box>
            </Box>
            {onDeleteButtonClicked && (
                <FilledCloseButtonWithWhiteCross
                    color={hasExceeded ? errorColor : darkGrey}
                    disabled={properties.disableDeleteButton}
                    onClick={(event) => {
                        event.preventDefault()

                        if (pdf) {
                            onDeleteButtonClicked(pdf)
                        } else if (uploadedPdf) {
                            onDeleteButtonClicked(uploadedPdf)
                        }
                    }}
                />
            )}
        </Container>
    )
}

const Container = styled(({ hasExceeded, ...properties }: LinkProps & { hasExceeded: boolean }) => (
    <Link {...properties} />
))(({ theme, hasExceeded }) => ({
    height: '88px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    gap: theme.spacing(1),
    border: hasExceeded ? `1px solid ${errorColor}` : `1px solid ${grey_3}`,
    borderRadius: theme.spacing(2),
    textDecoration: 'none',
}))

const ImageView = styled('img')(() => ({
    width: '56px',
    minWidth: '56px',
    height: '56px',
    objectFit: 'cover',
    borderRadius: '8px',
}))

const PdfNameTextView = styled(Typography)(() => ({
    display: 'inline-block',
    fontWeight: 700,
    fontSize: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    flexGrow: 1,
}))

const SizeTextView = styled(Typography)(() => ({
    fontSize: '12px',
}))

const ErrorTextView = styled(Typography)(({ theme }) => ({
    fontSize: '12px',
    marginLeft: theme.spacing(0.5),
}))
