import { SVGProps } from './SVGProps'

export const CheckIcon = (properties: SVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={properties.width ?? '24'}
            height={properties.height ?? '24'}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g clipPath="url(#clip0_836_15388)">
                <circle cx="12" cy="12" r="10" fill="white" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM17.6061 9.2522C17.938 8.93031 17.9462 8.40028 17.6243 8.06834C17.3024 7.7364 16.7724 7.72825 16.4404 8.05013L10.1163 14.1826L7.55956 11.7034C7.22762 11.3815 6.69759 11.3897 6.37571 11.7216C6.05383 12.0536 6.06198 12.5836 6.39392 12.9055L9.53346 15.9499C9.8582 16.2648 10.3744 16.2648 10.6991 15.9499L17.6061 9.2522Z"
                    fill={properties.color || 'current'}
                />
            </g>
            <defs>
                <clipPath id="clip0_836_15388">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
