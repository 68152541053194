import { SVGProps } from './SVGProps'

export const CloseIcon = (properties: SVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={properties.color || 'current'}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.5316 4.46967C19.8244 4.76258 19.8244 5.23745 19.5315 5.53033L5.53031 19.5303C5.2374 19.8232 4.76253 19.8232 4.46965 19.5303C4.17677 19.2374 4.17679 18.7625 4.46969 18.4696L18.4709 4.46963C18.7638 4.17675 19.2387 4.17677 19.5316 4.46967Z"
                fill="current"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.47067 4.4694C4.76357 4.17652 5.23845 4.17654 5.53133 4.46945L19.5313 18.4707C19.8242 18.7636 19.8242 19.2384 19.5313 19.5313C19.2384 19.8242 18.7635 19.8242 18.4706 19.5313L4.47062 5.53006C4.17774 5.23716 4.17776 4.76228 4.47067 4.4694Z"
                fill="current"
            />
        </svg>
    )
}
