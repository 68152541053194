import { SVGProps } from './SVGProps'

export const InfoIcon = (properties: SVGProps) => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 5.396c.242 0 .438.196.438.437v4.084a.438.438 0 1 1-.875 0V5.833c0-.241.195-.437.437-.437zM7 3.573a.51.51 0 1 0 0 1.02h.006a.51.51 0 1 0 0-1.02H7z"
            fill={properties.color}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 7a7 7 0 1 1 14 0A7 7 0 0 1 0 7zM7 .875a6.125 6.125 0 1 0 0 12.25A6.125 6.125 0 0 0 7 .875z"
            fill={properties.color}
        />
    </svg>
)
