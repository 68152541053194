export const Plus = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="current"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6508 12.0008C22.6508 12.415 22.315 12.7508 21.9008 12.7508L2.10092 12.7499C1.68671 12.7499 1.35093 12.4141 1.35095 11.9999C1.35097 11.5857 1.68677 11.2499 2.10098 11.2499L21.9008 11.2508C22.315 11.2508 22.6508 11.5866 22.6508 12.0008Z"
            fill="current"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0013 1.35095C12.4155 1.35097 12.7513 1.68677 12.7513 2.10098L12.7504 21.9008C12.7504 22.315 12.4146 22.6508 12.0004 22.6508C11.5862 22.6508 11.2504 22.315 11.2504 21.9008L11.2513 2.10092C11.2513 1.68671 11.5871 1.35093 12.0013 1.35095Z"
            fill="current"
        />
    </svg>
)
