import { GivingApiClient } from '../api/GivingApiClient'
import { Fundraiser } from '../main/fundraiser/Fundraiser'
import { PaymentMethod, SelectedPaymentMethod } from '../main/paymentMethods/PaymentMethod'
import { Charity } from '../model/Charity'
import { Transaction } from '../model/Transaction'
import { CreateTransactionBody } from '../model/api/CreateTransactionBody'

export class GivingRepository {
    constructor(private api: GivingApiClient) {}

    async donateToCharities(
        applicationId: string,
        selectedPaymentMethod: SelectedPaymentMethod,
        charities: Charity[]
    ): Promise<{ paymentUrl: string }> {
        const transactions = charities
            .filter((charity) => charity.amount > 0)
            .map((charity) => {
                return new CreateTransactionBody({
                    paymentMethod: selectedPaymentMethod.paymentMethod.type,
                    amount: charity.amount,
                    providerId: selectedPaymentMethod.provider?.id,
                    charityId: charity.id,
                })
            })
        return this.api.createTransaction(applicationId, transactions)
    }

    async donateToFundraiser(
        applicationId: string,
        selectedPaymentMethod: SelectedPaymentMethod,
        fundraiser: Fundraiser,
        amount: number
    ): Promise<{ paymentUrl: string }> {
        const transaction = new CreateTransactionBody({
            paymentMethod: selectedPaymentMethod.paymentMethod.type,
            amount: amount,
            providerId: selectedPaymentMethod.provider?.id,
            fundraiserId: fundraiser.id,
        })
        return this.api.createTransaction(applicationId, [transaction])
    }

    async getPaymentMethods(applicationId: string): Promise<PaymentMethod[]> {
        return this.api.getPaymentMethods(applicationId)
    }

    async getCharities(applicationId: string): Promise<Charity[]> {
        return this.api.getCharities(applicationId)
    }

    async getFundraiser(applicationId: string, fundraiserId: string): Promise<Fundraiser> {
        return this.api.getFundraiser(applicationId, fundraiserId)
    }

    async getTransaction(applicationId: string, transactionId: string): Promise<Transaction> {
        return this.api.getTransaction(applicationId, transactionId)
    }
}
