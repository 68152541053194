export const DeleteIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="current"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9718 1.49802C10.5074 1.49802 9.32027 2.68513 9.32027 4.14951V4.85173H2.30273C1.88852 4.85173 1.55273 5.18752 1.55273 5.60173C1.55273 6.01594 1.88852 6.35173 2.30273 6.35173H4.35193L5.17247 20.0104C5.25658 21.4106 6.41656 22.5029 7.81919 22.5029H16.2856C17.6928 22.5029 18.855 21.4036 18.933 19.9985L19.6907 6.35352L21.6971 6.35415C22.1112 6.35428 22.4477 6.01873 22.448 5.6046C22.4482 5.19047 22.1128 4.85452 21.6986 4.85415L18.982 4.85173C18.9654 4.85172 18.949 4.85224 18.9327 4.85328L14.7209 4.85197V4.14951C14.7209 2.68513 13.5338 1.49802 12.0694 1.49802H11.9718ZM10.0703 6.35173H5.85463L6.66977 19.9205C6.70629 20.5285 7.21005 21.0029 7.81919 21.0029H16.2856C16.8967 21.0029 17.4014 20.5255 17.4353 19.9153L18.1884 6.35305L13.9707 6.35173C13.9615 6.35173 13.9523 6.35156 13.9432 6.35123H10.098C10.0888 6.35156 10.0796 6.35173 10.0703 6.35173ZM10.8203 4.85123H13.2209V4.14951C13.2209 3.51356 12.7054 2.99802 12.0694 2.99802H11.9718C11.3358 2.99802 10.8203 3.51356 10.8203 4.14951V4.85123ZM10.8006 10.2149C10.8006 9.80072 10.4649 9.46493 10.0506 9.46493C9.63643 9.46493 9.30065 9.80072 9.30065 10.2149V17.1871C9.30065 17.6013 9.63643 17.9371 10.0506 17.9371C10.4649 17.9371 10.8006 17.6013 10.8006 17.1871V10.2149ZM13.945 9.46493C14.3593 9.46493 14.695 9.80072 14.695 10.2149V17.1871C14.695 17.6013 14.3593 17.9371 13.945 17.9371C13.5308 17.9371 13.195 17.6013 13.195 17.1871V10.2149C13.195 9.80072 13.5308 9.46493 13.945 9.46493Z"
            fill="current"
        />
    </svg>
)
