import { SVGProps } from 'react'

export const RoundedCheckBlack = (props: SVGProps<any>) => (
    <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#0v10wxb5fa)">
            <circle cx="12.5" cy="12" r="10" fill="#000" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 0C5.873 0 .5 5.373.5 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.606 9.252A.837.837 0 0 0 16.94 8.05l-6.324 6.133-2.556-2.48a.837.837 0 1 0-1.166 1.203l3.14 3.044a.837.837 0 0 0 1.165 0l6.907-6.698z"
                fill="current"
            />
        </g>
    </svg>
)
