import { Box, Link, styled, Typography } from '@mui/material'
import { memo, useEffect, useState } from 'react'
import { LinkPreview } from '../models/LinkPreview'
import { grey_3 } from '../theme/Theme'
import { FilledCloseButtonWithWhiteCross } from './buttons/CloseButton'

interface Properties {
    hasWhiteBackground?: boolean
    hasFailedLoadingLinkPreview?: boolean
    linkPreview?: LinkPreview
    onDeleteButtonClicked?: () => void

    translations: {
        loading_link_preview_failed: string
    }
}

export const Component = ({ linkPreview, translations, ...properties }: Properties) => {
    const hasWhiteBackground = properties.hasWhiteBackground ?? false

    const [thumbnailUrl, setThumbnailUrl] = useState(linkPreview?.thumbnailUrl)

    const DeleteButton = () => {
        if (!properties.onDeleteButtonClicked) {
            return <></>
        }
        return (
            <CloseButtonContainer>
                <FilledCloseButtonWithWhiteCross
                    onClick={(event) => {
                        event.preventDefault()
                        properties.onDeleteButtonClicked && properties.onDeleteButtonClicked()
                    }}
                />
            </CloseButtonContainer>
        )
    }

    useEffect(() => {
        setThumbnailUrl(linkPreview?.thumbnailUrl)
    }, [linkPreview])

    if (properties.hasFailedLoadingLinkPreview) {
        return (
            <Container bgcolor={hasWhiteBackground ? 'white' : grey_3}>
                <ContentContainer>
                    <TitleTextView variant="body2">
                        {translations['loading_link_preview_failed']}
                    </TitleTextView>
                </ContentContainer>
                <DeleteButton />
            </Container>
        )
    }

    if (!linkPreview) {
        return <></>
    }

    return (
        <Container
            bgcolor={hasWhiteBackground ? 'white' : grey_3}
            href={linkPreview.url}
            target="_blank"
            rel="noreferrer"
        >
            {thumbnailUrl && (
                <ImageView
                    bgColor={hasWhiteBackground ? grey_3 : 'white'}
                    src={thumbnailUrl}
                    onError={() => setThumbnailUrl(undefined)}
                    alt={linkPreview.title}
                />
            )}
            <ContentContainer>
                <TitleTextView variant="h4">{linkPreview.title}</TitleTextView>
                <DescriptionTextView variant="body1">{linkPreview.description}</DescriptionTextView>
                <UrlTextView variant="body2">{linkPreview.url}</UrlTextView>
            </ContentContainer>
            <DeleteButton />
        </Container>
    )
}

export const LinkPreviewView = memo(Component, (previous, next) => {
    return (
        previous.hasFailedLoadingLinkPreview === next.hasFailedLoadingLinkPreview &&
        previous.linkPreview === next.linkPreview
    )
})

const Container = styled(Link)(() => ({
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    borderRadius: '16px',
    height: '88px',
    minHeight: '88px',
}))

const ImageView = styled('img')<{ bgColor: string }>(({ bgColor }) => ({
    height: '86px',
    width: '120px',
    backgroundColor: bgColor,
    marginLeft: '1px',
    marginTop: '1px',
    marginBottom: '1px',
    borderTopLeftRadius: '16px',
    borderBottomLeftRadius: '16px',
    objectFit: 'cover',
}))

const ContentContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    textAlign: 'left',
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
}))

const TitleTextView = styled(Typography)(() => ({
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
}))

const DescriptionTextView = styled(Typography)(() => ({
    width: '100%',
    display: 'inline-block',
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}))

const UrlTextView = styled(Typography)(() => ({
    width: '100%',
    display: 'inline-block',
    fontSize: '12px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}))

const CloseButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2),
    marginLeft: theme.spacing(1),
}))
