import { Box, Typography, styled } from '@mui/material'
import { MediaPreview } from 'shared/lib/components/media/MediaPreview'
import { PdfView } from 'shared/lib/components/PdfView'
import { LinkPreviewView } from 'shared/lib/components/LinkPreviewView'
import { MediaItem } from 'shared/lib/models/MediaItem'
import { fileRepository } from '../..'
import { Fundraiser } from './Fundraiser'
import { useContext, useEffect, useState } from 'react'
import { FullScreenMediaContext } from 'shared/lib/components/media/FullScreenMediaContextProvider'
import { MessageLinkifyView } from 'shared/lib/components/message/MessageLinkifyView'
import { Church } from '../../model/Church'
import { DonateButton } from '../../components/buttons/DonateButton'
import { MAX_CONTENT_WIDTH } from '../../common/constants'
import { ContentContainer } from '../../components/ContentContainer'
import { ChurchTitleView } from '../../components/ChurchTitleView'
import { PoweredByImage } from '../../components/PoweredByImage'
import { useTranslation } from 'shared/lib/i18n'

interface Properties {
    church: Church
    fundraiser: Fundraiser
    onContinueButtonClicked: () => void
}

export const FundraiserView = ({ fundraiser, ...properties }: Properties) => {
    const translations = useTranslation()
    const { openFullscreenMedia } = useContext(FullScreenMediaContext)!

    const [width, setWidth] = useState(Math.min(window.innerWidth, MAX_CONTENT_WIDTH))

    const mediaItems = MediaItem.mapToMediaItems(
        {
            videos: fundraiser.videos,
            images: fundraiser.images,
        },
        fileRepository
    )

    const onResize = () => {
        setWidth(Math.min(window.innerWidth, MAX_CONTENT_WIDTH))
    }

    useEffect(() => {
        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    })

    return (
        <Container>
            <ChurchTitleView displayType="all" church={properties.church} />

            <ContentContainer hasStaticHeight={false}>
                <Box p={3}>
                    <TitleTextView>{fundraiser.name}</TitleTextView>

                    {mediaItems.length > 0 && (
                        <Box mt={2}>
                            <MediaPreview
                                width={width}
                                mediaItems={mediaItems}
                                onMediaItemSelected={openFullscreenMedia}
                            />
                        </Box>
                    )}

                    {fundraiser.description && (
                        <Box mt={2}>
                            <MessageLinkifyView
                                canCollapse={false}
                                isCollapsed={false}
                                className="description"
                                message={fundraiser.description}
                                linesToShowWhenCollapsed={0}
                                translations={{
                                    readLess: translations('read_less'),
                                }}
                                onCollapseClicked={() => {}}
                            />
                        </Box>
                    )}

                    {fundraiser.pdfs.length > 0 && (
                        <Box mt={2}>
                            <PdfView
                                pdf={fundraiser.pdfs[0]}
                                fileRepository={fileRepository}
                                translations={translations}
                            />
                        </Box>
                    )}

                    {fundraiser.linkPreviews.length > 0 && (
                        <Box mt={2}>
                            <LinkPreviewView
                                linkPreview={fundraiser.linkPreviews[0]}
                                translations={{
                                    loading_link_preview_failed: translations(
                                        'loading_link_preview_failed'
                                    ),
                                }}
                            />
                        </Box>
                    )}
                </Box>
            </ContentContainer>

            <PoweredByImage isMobile={true} />

            <PaymentButtonContainer>
                <DonateButton
                    isLoading={false}
                    isEnabled={true}
                    title={translations('choose_amount')}
                    onClick={properties.onContinueButtonClicked}
                />
            </PaymentButtonContainer>

            <PoweredByImage isMobile={false} />
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up('phablet')]: {
        paddingTop: theme.spacing(2),
    },
}))

const TitleTextView = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 'bold',
}))

const PaymentButtonContainer = styled(Box)(({ theme }) => ({
    position: 'sticky',
    marginTop: theme.spacing(2),
    bottom: theme.spacing(2),
    right: theme.spacing(2),
}))
