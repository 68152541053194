import { ReactComponent as PlusIcon } from '../../assets/svg/plus.svg'
import { ReactComponent as MinusIcon } from '../../assets/svg/minus.svg'
import { Box, IconButton as MuiIconButton, styled, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CharityAmountTextField } from './CharityAmountTextField'
import { Charity } from '../../model/Charity'

interface Properties {
    charity: Charity
    onAmountChanged: (charityId: string, value: number) => void
}

export const CharityView = (properties: Properties) => {
    const { t: translation } = useTranslation()
    const { charity } = properties

    const [amount, setAmount] = useState<number | undefined>(charity.amount)

    const name =
        charity.name === Charity.DEFAULT_CHARITY_NAME
            ? translation('default_charity_name')
            : charity.name

    const onDecreaseAmount = () => onAmountChanged(charity.amount - 0.5)

    const onAmountChanged = (value?: number) => {
        if (value && value < 0) return
        setAmount(value)
        charity.amount = value || 0
        properties.onAmountChanged(charity.id, charity.amount)
    }

    const onInCreaseAmount = () => onAmountChanged(charity.amount + 0.5)

    return (
        <Container>
            <Box pr={2}>
                <IconButton color="primary" onClick={onDecreaseAmount}>
                    <RemoveIcon />
                </IconButton>
            </Box>

            <Box flexGrow={1} textAlign="center">
                <Typography variant="body1" fontSize="14px" fontWeight={400}>
                    {name}
                </Typography>
                <CharityAmountTextField
                    name={charity.id}
                    value={amount}
                    onChange={onAmountChanged}
                />
            </Box>

            <Box pl={2}>
                <IconButton color="primary" onClick={onInCreaseAmount}>
                    <AddIcon />
                </IconButton>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    minHeight: '110px',
    paddingLeft: '16px',
    paddingRight: '16px',
}))

const IconButton = styled(MuiIconButton)(() => ({
    height: '40px',
    width: '40px',
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
}))

const RemoveIcon = styled(MinusIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    fill: theme.palette.primary.main,
}))

const AddIcon = styled(PlusIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    fill: theme.palette.primary.main,
}))
