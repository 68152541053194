import { SVGProps } from './SVGProps'

export const ChevronUpIcon = (properties: SVGProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.color || 'current'}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2803 15.2803C19.9874 15.5732 19.5126 15.5732 19.2197 15.2803L11.75 7.81066L4.28033 15.2803C3.98744 15.5732 3.51256 15.5732 3.21967 15.2803C2.92678 14.9874 2.92678 14.5126 3.21967 14.2197L11.2197 6.21967C11.5126 5.92678 11.9874 5.92678 12.2803 6.21967L20.2803 14.2197C20.5732 14.5126 20.5732 14.9874 20.2803 15.2803Z"
            fill="current"
        />
    </svg>
)
