import React, { useCallback } from 'react'
import { renderToString } from 'react-dom/server'
import * as reactI18n from 'react-i18next'
import { TranslationFn } from './WithTranslations'

export const useTranslation = (): TranslationFn => {
    const { t: translations } = reactI18n.useTranslation()

    return useCallback(
        (key: string, params?: (string | number | React.JSX.Element)[], count?: number) => {
            if (!params) {
                return translations(key)
            }

            return translations(key, {
                postProcess: 'sprintf',
                sprintf: params.map((param) => {
                    if (React.isValidElement(param)) {
                        return renderToString(param)
                    }

                    return param
                }),
                count,
            })
        },
        [translations]
    )
}
