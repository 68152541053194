import { Box, IconButton as MuiIconButton, styled } from '@mui/material'
import { grey_2, grey_3 } from 'shared/lib/theme/constants'
import { ReactComponent as ChevronLeftIcon } from '../../assets/svg/chevron_left.svg'

interface Properties {
    color?: 'white' | 'grey'
    onClick: () => void
}

export const BackButton = (properties: Properties) => {
    return (
        <Container>
            <IconButton
                disableRipple={true}
                sx={{
                    backgroundColor: properties.color === 'grey' ? grey_3 : 'white',
                }}
                color="primary"
                onClick={properties.onClick}
            >
                <ChevronLeft />
            </IconButton>
        </Container>
    )
}

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const IconButton = styled(MuiIconButton)(() => ({
    height: '48px',
    width: '48px',
    borderRadius: '16px',

    '&:hover': {
        backgroundColor: grey_2,
    },
}))

const ChevronLeft = styled(ChevronLeftIcon)(() => ({
    width: '16px',
    height: '16px',
}))
