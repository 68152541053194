import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { grey_3 } from 'shared/lib/theme/constants'
import { ReactComponent as Heart } from '../../assets/svg/ic_heart.svg'
import { CurrencyFormatter } from '../../utilities/Utils'

interface Properties {
    amount: number
}

export const DonationSuccessView = (properties: Properties) => {
    const { t: translation } = useTranslation()
    const { amount } = properties

    return (
        <Container>
            <Box mt={'-8px'}>
                <HeartIcon />
            </Box>

            <Typography variant="body1" fontSize="16px" fontWeight={600}>
                {translation('donation_succeeded')}
            </Typography>

            <Typography variant="body2" fontSize="16px">
                {translation('donation_succeeded_message', {
                    amount: CurrencyFormatter.format(amount),
                })}
            </Typography>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    margin: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    justifyItems: 'center',
    background: grey_3,
    borderRadius: '24px',

    [theme.breakpoints.only('mobile')]: {
        marginTop: 0,
    },
}))

const HeartIcon = styled(Heart)(({ theme }) => ({
    fill: theme.palette.primary.main,
}))
