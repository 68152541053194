import { Image } from '../models/Image'

export class FileRepository {
    constructor(private readonly cdnUrl: string) {}

    public getFileUrl(key?: string) {
        return key ? `${this.cdnUrl}/${encodeURIComponent(key)}` : undefined
    }

    public getImageUrl(image?: Image) {
        return image ? `${this.cdnUrl}/${encodeURIComponent(image.key)}` : undefined
    }

    public getThumbnailUrl(image?: Image) {
        return image && image.thumbnailKey
            ? `${this.cdnUrl}/${encodeURIComponent(image.thumbnailKey)}`
            : undefined
    }
}
