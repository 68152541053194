'use client'

import { Portal, Snackbar } from '@mui/material'
import { useEffect, useState } from 'react'
import { WithTranslations } from '../WithTranslations'
import { CloseButton } from './buttons/CloseButton'
import { BadRequestError, getErrorMessage } from '../utils/ErrorUtils'

interface Properties {
    error?: any
    badRequestError?: BadRequestError
    locale?: string
    horizontal?: 'left' | 'center' | 'right'
}

export const ErrorHandler = ({ translations, ...properties }: WithTranslations<Properties>) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const onCloseButtonClicked = () => setErrorMessage(undefined)

    useEffect(() => {
        setErrorMessage(
            getErrorMessage({
                error: properties.error,
                badRequestError: properties.badRequestError,
                locale: properties.locale,
                translations,
            })
        )
    }, [properties.error]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Portal>
            <Snackbar
                open={Boolean(errorMessage)}
                onClose={onCloseButtonClicked}
                autoHideDuration={5000}
                message={errorMessage}
                action={<CloseButton color="white" onClick={onCloseButtonClicked} />}
                anchorOrigin={{ vertical: 'bottom', horizontal: properties.horizontal || 'center' }}
            />
        </Portal>
    )
}
