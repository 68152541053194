export class ImageSizeDeterminator {
    readonly width: number
    readonly height: number

    constructor(widthOfImage: number, heightOfImage: number) {
        this.width = Math.max(widthOfImage, 1)
        this.height = Math.max(heightOfImage, 1)
    }

    private get aspectRatioMax(): number {
        return Math.min(Math.max(this.width / this.height, 0.75), 1.5)
    }

    get isPanorama(): boolean {
        return this.width / this.height > 3
    }

    proportionalHeightMax(parentWidth: number): number {
        return parentWidth / this.aspectRatioMax
    }
}
