import { Box, Button, styled, Typography } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import { grey_3 } from 'shared/lib/theme/constants'
import { ReactComponent as AlertIcon } from '../../assets/svg/ic_alert.svg'

interface Properties {
    onTryAgainButtonClicked: () => void
}

export const DonationFailedView = (properties: Properties) => {
    const translations = useTranslation()

    return (
        <Container>
            <Box display="flex" flexDirection="row">
                <Box ml={2} mt={2} display="flex" width="40px" height="40px">
                    <AlertIcon />
                </Box>

                <Box ml={2} mr={2} mt={2} display="flex" flexDirection="column" flexGrow={1}>
                    <Typography variant="body1" fontSize="16px" fontWeight={600}>
                        {translations('donation_failed')}
                    </Typography>

                    <Typography variant="body2" fontSize="16px">
                        {translations('donation_failed_message')}
                    </Typography>

                    <Box mt={3} mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={properties.onTryAgainButtonClicked}
                            fullWidth={true}
                            sx={{
                                color: 'white',
                            }}
                        >
                            {translations('try_again')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    margin: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    background: grey_3,
    borderRadius: '24px',

    [theme.breakpoints.only('mobile')]: {
        marginTop: 0,
    },
}))
