import { SVGProps } from './SVGProps'

export const CancelWhiteIcon = (properties: SVGProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g fill="current" fillRule="evenodd">
                <g transform="translate(-748 -716) translate(232 571) translate(32 137) translate(484 8)">
                    <circle cx="12" cy="12" r="12" fill={properties.color} />
                    <g
                        stroke="#FFFFFF"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    >
                        <path d="M8 0L0 8M0 0L8 8" transform="translate(8 8)" />
                    </g>
                </g>
            </g>
        </svg>
    )
}
