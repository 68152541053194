export class CreateTransactionBody {
    readonly paymentMethod: string
    readonly type = 'CONTRIBUTION'
    readonly amount: number
    readonly providerId?: string
    readonly charityId?: string
    readonly fundraiserId?: string

    constructor(params: {
        paymentMethod: string
        amount: number
        providerId?: string
        charityId?: string
        fundraiserId?: string
    }) {
        this.paymentMethod = params.paymentMethod
        this.amount = params.amount
        this.providerId = params.providerId
        this.charityId = params.charityId
        this.fundraiserId = params.fundraiserId
    }
}
