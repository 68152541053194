import { Image } from 'shared/lib/models/Image'
import { Video } from 'shared/lib/models/Video'
import { LinkPreview } from 'shared/lib/models/LinkPreview'
import { Pdf } from 'shared/lib/models/Pdf'

export class Fundraiser {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly description: string,
        readonly images: Image[],
        readonly videos: Video[],
        readonly linkPreviews: LinkPreview[],
        readonly pdfs: Pdf[],
        readonly isExpired: boolean
    ) {}

    static fromResponse(response: any): Fundraiser {
        return new Fundraiser(
            response.id,
            response.name,
            response.description,
            response.images || [],
            response.videos || [],
            response.linkPreviews || [],
            response.pdfs || [],
            response.isExpired
        )
    }
}
