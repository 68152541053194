import {
    Box,
    ListItem,
    ListItemButtonProps,
    ListItemButton as MuiListItemButton,
    ListItemText as MuiListItemText,
    Typography,
    styled,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import Theme from 'shared/lib/theme/Theme'
import { grey_3 } from 'shared/lib/theme/constants'
import { ReactComponent as SelectedIcon } from '../../assets/svg/ic_selected.svg'
import { PaymentMethod, PaymentMethodProvider, SelectedPaymentMethod } from './PaymentMethod'

interface Properties {
    hasBottomDivider: boolean
    showAllProviders: boolean
    paymentMethod: PaymentMethod
    selectedPaymentMethod?: SelectedPaymentMethod
    onShowMoreProvidersClicked: (paymentMethod: PaymentMethod) => void
    onPaymentMethodSelected: (
        paymentMethod: PaymentMethod,
        provider?: PaymentMethodProvider
    ) => void
}

export const PaymentMethodListItem = (properties: Properties) => {
    const { t: translations } = useTranslation()

    const { showAllProviders, paymentMethod, selectedPaymentMethod } = properties

    const isSelected = paymentMethod.isSelected(selectedPaymentMethod)

    const providers = paymentMethod.providers || []
    const hasProviders = providers.length > 0
    const maxProviders = showAllProviders
        ? providers.length
        : providers.length > 4
          ? 3
          : providers.length > 1 && providers.length <= 4
            ? 4
            : 0
    const hasShowMoreListItem = !showAllProviders && providers.length > 4

    const uniqueProviderTransactionAmountCosts = new Set(
        providers.map((provider) => provider.transactionCostsAmount)
    )
    const uniqueProviderTransactionPercentageCosts = new Set(
        providers.map((provider) => provider.transactionCostsPercentage)
    )
    const hasAllProviderSameTransactionCosts =
        uniqueProviderTransactionAmountCosts.size === 1 &&
        uniqueProviderTransactionPercentageCosts.size === 1

    return (
        <>
            <ListItem disablePadding={true}>
                <ListItemButton
                    hasProviders={hasProviders}
                    onClick={() => properties.onPaymentMethodSelected(paymentMethod)}
                >
                    {isSelected && <SelectedView />}

                    <Image src={paymentMethod.iconImageUrl} alt={paymentMethod.name} />

                    <Box display="flex" flexGrow={1}>
                        <ListItemPaymentMethodText>{paymentMethod.name}</ListItemPaymentMethodText>
                    </Box>

                    <Box display="flex" alignItems="center" gap={2}>
                        {(hasAllProviderSameTransactionCosts || !hasProviders) && (
                            <TransactionCostsTextView>
                                {paymentMethod.formattedTransactionCosts}
                            </TransactionCostsTextView>
                        )}
                        {isSelected && <SelectedIcon fill={Theme.palette.primary.main} />}
                    </Box>
                </ListItemButton>
            </ListItem>

            {providers.slice(0, maxProviders).map((provider) => {
                const isSelected = provider.isSelected(properties.selectedPaymentMethod)
                return (
                    <ListItem key={provider.id} disablePadding={true}>
                        <ListItemButton
                            isProvider={true}
                            onClick={() =>
                                properties.onPaymentMethodSelected(paymentMethod, provider)
                            }
                        >
                            {isSelected && <SelectedView />}

                            <Box display="flex" width="24px">
                                {isSelected && <SelectedIcon fill={Theme.palette.primary.main} />}
                            </Box>

                            <Box display="flex" flexGrow={1} pl={2}>
                                <ListItemText>{provider.name}</ListItemText>
                            </Box>
                        </ListItemButton>
                    </ListItem>
                )
            })}

            {hasShowMoreListItem && (
                <ListItem key={`${paymentMethod.name}-show-more`} disablePadding={true}>
                    <ListItemButton
                        isProvider={true}
                        onClick={() => properties.onShowMoreProvidersClicked(paymentMethod)}
                    >
                        <Box display="flex">
                            {isSelected && <SelectedIcon fill={Theme.palette.primary.main} />}
                        </Box>

                        <Box display="flex" flexGrow={1} pl={5}>
                            <ListItemText>{translations('show_more')}</ListItemText>
                        </Box>
                    </ListItemButton>
                </ListItem>
            )}

            <Divider
                key={`${paymentMethod.name}-divider`}
                sx={(theme) => ({
                    backgroundColor: properties.hasBottomDivider ? grey_3 : 'white',
                    [theme.breakpoints.only('mobile')]: {
                        backgroundColor: properties.hasBottomDivider ? 'white' : grey_3,
                    },
                })}
            />
        </>
    )
}

const ListItemButton = styled(
    ({
        isProvider,
        hasProviders,
        ...properties
    }: ListItemButtonProps & { isProvider?: boolean; hasProviders?: boolean }) => (
        <MuiListItemButton {...properties} />
    )
)(({ theme, isProvider, hasProviders }) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '22px',
    marginLeft: theme.spacing(isProvider ? 2 : 0),
    marginRight: theme.spacing(isProvider ? 2 : 0),
    paddingLeft: theme.spacing(isProvider ? 1.5 : 3),
    paddingRight: theme.spacing(isProvider ? 1.5 : 3),
    paddingTop: theme.spacing(isProvider ? 1 : 3),
    paddingBottom: theme.spacing(isProvider ? 1 : hasProviders ? 1 : 3),
    pointerEvents: hasProviders ? 'none' : 'auto',
}))

const SelectedView = styled(Box)(({ theme }) => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: 0,
    borderRadius: '22px',
    backgroundColor: theme.palette.primary.main,
    opacity: 0.3,
}))

const Image = styled('img')(({ theme }) => ({
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(2),
}))

const ListItemText = styled(MuiListItemText)(() => ({
    '& span': {
        fontSize: '16px',
    },
}))

const ListItemPaymentMethodText = styled(MuiListItemText)(() => ({
    '& span': {
        fontSize: '16px',
        fontWeight: 700,
    },
}))

const TransactionCostsTextView = styled(Typography)(() => ({
    fontSize: '12px',
}))

const Divider = styled('li')(({ theme }) => ({
    height: '1px',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
}))
