import { Box, Dialog, Fade, IconButton as MuiIconButton, styled } from '@mui/material'
import { MediaItem } from '../../models/MediaItem'
import { useEffect, useState } from 'react'
import { ReactComponent as Close } from 'shared/lib/assets/svg/close.svg'
import { ReactComponent as RightChevron } from 'shared/lib/assets/svg/chevron_right.svg'
import { ReactComponent as LeftChevron } from 'shared/lib/assets/svg/chevron_left.svg'
import { WithTranslations } from '../../WithTranslations'

interface MediaFullscreenProperties {
    mediaItems: MediaItem[]
    currentMediaIndex: number
    onClose: () => void
}

export const MediaFullscreen = ({
    mediaItems,
    translations,
    ...properties
}: WithTranslations<MediaFullscreenProperties>) => {
    const [mediaIndex, setMediaIndex] = useState<number>(properties.currentMediaIndex!)
    const [isMouseMoving, setIsMouseMoving] = useState(true)
    const [videoPlayerRef, setVideoPlayerRef] = useState<any>(undefined)
    const [isPictureInPicture, setIsPictureInPicture] = useState<boolean>(false)

    const getUrl = (index: number): string => {
        if (mediaItems[index].type === 'VIDEO') {
            return mediaItems[index].videoUrl!
        }
        return mediaItems[index].url
    }

    const getIsVideo = (index: number): boolean => {
        return mediaItems[index].type === 'VIDEO'
    }

    const onNext = () => {
        const nextIndex = mediaIndex + 1 <= mediaItems.length - 1 ? mediaIndex + 1 : 0
        setMediaIndex(nextIndex)
        videoPlayerRef?.load()
    }

    const onPrevious = () => {
        const previousIndex = mediaIndex - 1 >= 0 ? mediaIndex - 1 : mediaItems.length - 1
        setMediaIndex(previousIndex)
        videoPlayerRef?.load()
    }

    const onClose = () => {
        properties.onClose()
        videoPlayerRef?.pause()
        videoPlayerRef?.removeAttribute('src')
        videoPlayerRef?.load()
    }

    useEffect(() => {
        let timeoutId: NodeJS.Timeout

        const handleMouseMoveOrClick = () => {
            setIsMouseMoving(true)

            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => {
                setIsMouseMoving(false)
            }, 2500)
        }

        const handleMouseStop = () => {
            setIsMouseMoving(false)
        }

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                event.preventDefault()
                onClose()
            }
        }

        const handleEnterPictureInPicture = () => {
            setIsPictureInPicture(true)
        }

        const handleLeavePictureInPicture = () => {
            setIsPictureInPicture(false)
        }

        document.addEventListener('mousemove', handleMouseMoveOrClick)
        document.addEventListener('mousedown', handleMouseMoveOrClick)
        document.addEventListener('mouseleave', handleMouseStop)
        document.addEventListener('keydown', handleKeyDown)
        document.addEventListener('enterpictureinpicture', handleEnterPictureInPicture)
        document.addEventListener('leavepictureinpicture', handleLeavePictureInPicture)

        return () => {
            document.removeEventListener('mousemove', handleMouseMoveOrClick)
            document.removeEventListener('mousedown', handleMouseMoveOrClick)
            document.removeEventListener('mouseleave', handleMouseStop)
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('enterpictureinpicture', handleEnterPictureInPicture)
            document.removeEventListener('leavepictureinpicture', handleLeavePictureInPicture)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container
            open={true}
            fullScreen={true}
            fullWidth={true}
            disableEscapeKeyDown={true}
            sx={{
                display: isPictureInPicture ? 'none' : 'initial',
            }}
        >
            {getIsVideo(mediaIndex) && (
                <VideoView controls autoPlay ref={setVideoPlayerRef}>
                    <source src={getUrl(mediaIndex)} type="video/mp4" />
                    Your browser does not support the video tag.
                </VideoView>
            )}

            {!getIsVideo(mediaIndex) && <ImageView src={getUrl(mediaIndex)} />}
            <Fade in={isMouseMoving}>
                <Box>
                    {mediaItems.length > 1 && (
                        <GalleryCount>
                            {translations?.('attachment') ?? 'Attachment'} {mediaIndex + 1} /{' '}
                            {mediaItems.length}
                        </GalleryCount>
                    )}
                    <IconButtonTop aria-label="close" onClick={onClose}>
                        <Close fill={'#fff'} />
                    </IconButtonTop>
                    {mediaItems.length > 1 && (
                        <>
                            <IconButtonLeft aria-label={'previous'} onClick={onPrevious}>
                                <LeftChevron fill={'#fff'} />
                            </IconButtonLeft>
                            <IconButtonRight aria-label={'next'} onClick={onNext}>
                                <RightChevron fill={'#fff'} />
                            </IconButtonRight>
                        </>
                    )}
                </Box>
            </Fade>
        </Container>
    )
}

const Container = styled(Dialog)(() => ({
    backgroundColor: 'black',
    userSelect: 'none',
    '& .MuiDialog-paper': {
        margin: 0,
        padding: 0,
        borderRadius: 0,
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
    },
}))

const ImageView = styled('img')(() => ({
    display: 'block',
    margin: 'auto',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
}))

const VideoView = styled('video')(() => ({
    display: 'block',
    margin: 'auto',
}))

const IconButton = styled(MuiIconButton)(() => ({
    borderRadius: '32px',
    background: 'rgba(127, 127, 127, 0.30)',
    backdropFilter: 'blur(4px)',
    zIndex: 3001,
    position: 'fixed',
    '& svg': {
        height: '32px',
        width: '32px',
    },
}))

const IconButtonRight = styled(IconButton)(({ theme }) => ({
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
    marginRight: theme.spacing(4),
    [theme.breakpoints.only('mobile')]: {
        marginRight: theme.spacing(1),
    },
}))

const IconButtonLeft = styled(IconButton)(({ theme }) => ({
    top: '50%',
    transform: 'translateY(-50%)',
    left: 0,
    marginLeft: theme.spacing(4),
    [theme.breakpoints.only('mobile')]: {
        marginLeft: theme.spacing(1),
    },
}))

const IconButtonTop = styled(IconButton)(({ theme }) => ({
    top: 0,
    right: 0,
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(4),
    [theme.breakpoints.only('mobile')]: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}))

const GalleryCount = styled(Box)(({ theme }) => ({
    borderRadius: '32px',
    background: 'rgba(127, 127, 127, 0.30)',
    backdropFilter: 'blur(4px)',
    zIndex: 3001,
    position: 'fixed',
    marginTop: theme.spacing(4),
    [theme.breakpoints.only('mobile')]: {
        marginTop: theme.spacing(1),
    },
    top: 4.5,
    left: '50%',
    transform: 'translateX(-50%)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: '#fff',
    fontSize: '16px',
    fontWeight: 400,
}))
