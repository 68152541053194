import { CurrencyFormatter } from '../../utilities/Utils'

export class PaymentMethodProvider {
    constructor(
        readonly id: string,
        readonly name: string,
        readonly transactionCostsAmount?: number,
        readonly transactionCostsPercentage?: number
    ) {}

    get formattedTransactionCosts(): string | undefined {
        return formatTransactionCosts({
            transactionCostsAmount: this.transactionCostsAmount,
            transactionCostsPercentage: this.transactionCostsPercentage,
        })
    }

    isSelected(selectedPaymentMethod: SelectedPaymentMethod | undefined): boolean {
        return selectedPaymentMethod?.provider === this
    }

    static fromResponse(response: any): PaymentMethodProvider {
        return new PaymentMethodProvider(
            response.id,
            response.name,
            response.transactionCostsAmount,
            response.transactionCostsPercentage
        )
    }
}

export class PaymentMethod {
    constructor(
        readonly type: string,
        readonly name: string,
        readonly iconImageUrl: string,
        readonly transactionCostsAmount?: number,
        readonly transactionCostsPercentage?: number,
        readonly providers?: PaymentMethodProvider[]
    ) {}

    get formattedTransactionCosts(): string | undefined {
        const firstProvider = this.providers?.[0]
        return formatTransactionCosts({
            transactionCostsAmount:
                this.transactionCostsAmount ?? firstProvider?.transactionCostsAmount,
            transactionCostsPercentage:
                this.transactionCostsPercentage ?? firstProvider?.transactionCostsPercentage,
        })
    }

    isSelected(selectedPaymentMethod: SelectedPaymentMethod | undefined): boolean {
        if (selectedPaymentMethod?.provider !== undefined) {
            return false
        }

        return selectedPaymentMethod?.paymentMethod === this
    }

    static fromResponse(response: any): PaymentMethod {
        return new PaymentMethod(
            response.type,
            response.name,
            response.iconImageUrl,
            response.transactionCostsAmount,
            response.transactionCostsPercentage,
            response.providers?.map((provider: any) => PaymentMethodProvider.fromResponse(provider))
        )
    }
}

export class SelectedPaymentMethod {
    constructor(
        readonly paymentMethod: PaymentMethod,
        readonly provider?: PaymentMethodProvider
    ) {}
}

const formatTransactionCosts = (params: {
    transactionCostsAmount?: number
    transactionCostsPercentage?: number
}): string | undefined => {
    const { transactionCostsAmount, transactionCostsPercentage } = params

    if (transactionCostsAmount && transactionCostsPercentage) {
        return `${CurrencyFormatter.format(
            transactionCostsAmount
        )} + ${transactionCostsPercentage}%`
    }

    if (transactionCostsAmount) {
        return `+ ${CurrencyFormatter.format(transactionCostsAmount)}`
    }

    if (transactionCostsPercentage) {
        return `+ ${transactionCostsPercentage}%`
    }

    return undefined
}
