import { Box, BoxProps, Typography, styled } from '@mui/material'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { grey_3 } from 'shared/lib/theme/constants'
import { ChurchTitleView } from '../../components/ChurchTitleView'
import { ContentContainer } from '../../components/ContentContainer'
import { PageTitleView } from '../../components/PageTitleView'
import { PoweredByImage } from '../../components/PoweredByImage'
import { DonateButton } from '../../components/buttons/DonateButton'
import { Church } from '../../model/Church'
import { Fundraiser } from '../fundraiser/Fundraiser'
import { AmountTextField } from './AmountTextField'
import { PrefilledAmount } from './PrefilledAmount'

interface Properties {
    isLoading: boolean
    canContinue: boolean
    church: Church
    fundraiser: Fundraiser
    prefilledAmount?: PrefilledAmount
    enteredAmount?: number
    onBackButtonClicked: () => void
    onAmountChanged: (params: { prefilledAmount?: PrefilledAmount; enteredAmount?: number }) => void
    onContinueButtonClicked: () => void
}

export const ChooseAmountView = ({ enteredAmount, ...properties }: Properties) => {
    const { t: translations } = useTranslation()

    const amountInputRef = useRef<HTMLInputElement | null>(null)

    const [hasAmountInputFocus, setHasAmountInputFocus] = useState(false)

    const prefilledAmounts = [
        PrefilledAmount.FIFTY,
        PrefilledAmount.HUNDRED,
        PrefilledAmount.HUNDRED_FIFTY,
    ]

    const isSelected = (prefilledAmount: PrefilledAmount) => {
        return enteredAmount === undefined && properties.prefilledAmount === prefilledAmount
    }

    const onAmountInputFocus = () => {
        amountInputRef.current!.value = ''
        setHasAmountInputFocus(true)
        properties.onAmountChanged({ prefilledAmount: undefined })
    }

    const onAmountInputBlur = () => {
        setHasAmountInputFocus(false)
    }

    return (
        <>
            <Box mb={2}>
                <ChurchTitleView displayType="mobile_only" church={properties.church} />
                <PageTitleView
                    displayType="phablet_tablet_desktop_only"
                    title={translations('choose_amount')}
                    onBackButtonClicked={properties.onBackButtonClicked}
                />

                <ContentContainer hasStaticHeight={false}>
                    <PageTitleView
                        displayType="mobile_only"
                        backButtonColor="grey"
                        title={translations('choose_amount')}
                        onBackButtonClicked={properties.onBackButtonClicked}
                    />

                    <Box p={3}>
                        <Box display="flex" flexGrow={1} gap={3}>
                            {prefilledAmounts.map((prefilledAmount) => (
                                <AmountContainer
                                    key={prefilledAmount}
                                    onClick={() =>
                                        properties.onAmountChanged({
                                            prefilledAmount,
                                            enteredAmount: undefined,
                                        })
                                    }
                                >
                                    <AmountSelectedContainer
                                        isSelected={isSelected(prefilledAmount)}
                                        hasFocus={false}
                                    />
                                    <AmountTextView>{`€ ${prefilledAmount}`}</AmountTextView>
                                </AmountContainer>
                            ))}
                        </Box>

                        <EnterAmountContainer>
                            <AmountSelectedContainer
                                isSelected={!!enteredAmount}
                                hasFocus={hasAmountInputFocus}
                            />
                            <EuroTextView>€</EuroTextView>
                            <AmountTextField
                                name="amount"
                                inputRef={amountInputRef}
                                value={enteredAmount}
                                onFocus={onAmountInputFocus}
                                onBlur={onAmountInputBlur}
                                onChange={(value) =>
                                    properties.onAmountChanged({
                                        enteredAmount: value,
                                        prefilledAmount: properties.prefilledAmount,
                                    })
                                }
                            />
                        </EnterAmountContainer>
                    </Box>
                </ContentContainer>
            </Box>

            <PoweredByImage isMobile={true} />

            <DonateButton
                isLoading={properties.isLoading}
                isEnabled={properties.canContinue}
                title={translations('next')}
                onClick={properties.onContinueButtonClicked}
            />

            <PoweredByImage isMobile={false} />
        </>
    )
}

const AmountContainer = styled(Box)(() => ({
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    flexBasis: 0,
    flexGrow: 1,
    justifyContent: 'center',
    borderRadius: '16px',
}))

const AmountSelectedContainer = styled(
    ({
        isSelected,
        hasFocus,
        ...properties
    }: BoxProps & { isSelected: boolean; hasFocus: boolean }) => <Box {...properties} />
)(({ theme, isSelected, hasFocus }) => ({
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '16px',
    backgroundColor: isSelected ? `${theme.palette.primary.main}4D` : grey_3,
    borderColor: isSelected || hasFocus ? theme.palette.primary.main : grey_3,
    borderWidth: '1px',
    borderStyle: 'solid',
}))

const AmountTextView = styled(Typography)(({ theme }) => ({
    fontSize: '24px',
    fontWeight: 'bold',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    zIndex: 1,
}))

const EnterAmountContainer = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    backgroundColor: grey_3,
    borderRadius: '16px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
}))

const EuroTextView = styled(Typography)(() => ({
    fontSize: '24px',
    fontWeight: 800,
    zIndex: 1,
}))
