import axiosStatic, { AxiosResponse } from 'axios'
import { GivingDonkeyAppInfo } from '../common/GivingDonkeyAppInfo'

export abstract class ApiClient {
    private axios = axiosStatic.create()

    private config = (applicationId: string) => {
        return {
            headers: {
                'donkey-app-info': JSON.stringify(new GivingDonkeyAppInfo(applicationId)),
            },
        }
    }

    constructor(protected url: string) {}

    protected async post<T>(url: string, applicationId: string, data?: any): Promise<T> {
        return this.axios
            .post(url, data, this.config(applicationId))
            .then((response: AxiosResponse<T>) => response.data)
    }

    protected async get<T>(url: string, applicationId: string): Promise<T> {
        return this.axios
            .get(url, this.config(applicationId))
            .then((response: AxiosResponse<T>) => response.data)
    }
}
