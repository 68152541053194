import { Link, LinkProps, styled, Typography, TypographyProps } from '@mui/material'
import Linkify from 'linkify-react'
import { ReadLessTextView } from './ReadLessTextView'
import { MessageChildViewProperties } from './MessageChildViewProperties'

export const MessageLinkifyView = (properties: MessageChildViewProperties) => {
    const canCollapse = properties.canCollapse ?? true

    return (
        <MessageTextView
            className={properties.className}
            isCollapsed={properties.isCollapsed}
            linesToShowWhenCollapsed={properties.linesToShowWhenCollapsed}
        >
            <Linkify
                options={{
                    defaultProtocol: 'https',
                    target: '_blank',
                    rel: 'noreferrer',
                    render: ({ attributes, content }) => {
                        return <ExternalLink href={attributes.href} title={content} />
                    },
                }}
            >
                {properties.message}
            </Linkify>
            {canCollapse && !properties.isCollapsed && (
                <ReadLessTextView
                    translations={properties.translations}
                    onClicked={properties.onCollapseClicked}
                />
            )}
        </MessageTextView>
    )
}

const ExternalLink = ({ title, ...properties }: LinkProps & { title: string }) => (
    <Link component="a" rel="noreferrer" target="_blank" {...properties}>
        {title}
    </Link>
)

const MessageTextView = styled(
    ({
        isCollapsed,
        linesToShowWhenCollapsed,
        ...properties
    }: TypographyProps & { isCollapsed: boolean; linesToShowWhenCollapsed?: number }) => (
        <Typography variant="body1" {...properties} />
    )
)(({ isCollapsed, linesToShowWhenCollapsed }) => ({
    display: isCollapsed ? '-webkit-box' : 'block',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: isCollapsed ? linesToShowWhenCollapsed ?? 8 : 'unset',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'unset',
}))
