import { Church } from '../model/Church'
import { ApiClient } from './ApiClient'


export class ChurchApiClient extends ApiClient {
    async getChurch(applicationId: string): Promise<Church> {
        return await this.get<Church[]>(
            `${this.url}/api/v1/churches/?applicationId=${applicationId}`,
            applicationId
        ).then((response) => response[0])
    }
}
