import { SVGProps } from './SVGProps'

export const ChevronRightIcon = (properties: SVGProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.color ?? 'current'}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.96967 20.2803C7.67678 19.9874 7.67678 19.5126 7.96967 19.2197L15.4393 11.75L7.96967 4.28033C7.67678 3.98744 7.67678 3.51256 7.96967 3.21967C8.26256 2.92678 8.73744 2.92678 9.03033 3.21967L17.0303 11.2197C17.3232 11.5126 17.3232 11.9874 17.0303 12.2803L9.03033 20.2803C8.73744 20.5732 8.26256 20.5732 7.96967 20.2803Z"
            fill={properties.color ?? 'current'}
        />
    </svg>
)
