import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { fileRepository } from '..'
import { Church } from '../model/Church'
import { getAppleOrAndroidAppStoreUrl } from '../utilities/Utils'

interface Properties {
    displayType: 'mobile_only' | 'phablet_tablet_desktop_only' | 'all'
    church: Church
}

export const ChurchTitleView = ({ church, ...properties }: Properties) => {
    const isMobileOnly = properties.displayType === 'mobile_only'
    const isPhabletTabletDestkopOnly = properties.displayType === 'phablet_tablet_desktop_only'
    const isAll = properties.displayType === 'all'

    return (
        <Container
            display={{
                mobile: isAll || isMobileOnly ? 'flex' : 'none',
                phablet: isAll || isPhabletTabletDestkopOnly ? 'flex' : 'none',
                tablet: isAll || isPhabletTabletDestkopOnly ? 'flex' : 'none',
                desktop: isAll || isPhabletTabletDestkopOnly ? 'flex' : 'none',
            }}
            onClick={() => window.open(getAppleOrAndroidAppStoreUrl(church))}
        >
            <Image src={fileRepository.getThumbnailUrl(church.image)} alt={church.name} />
            <TitleTextView variant="h3">{church.name}</TitleTextView>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: theme.spacing(3),

    [theme.breakpoints.up('phablet')]: {
        justifyContent: 'flex-start',
        paddingLeft: 0,
        marginTop: theme.spacing(4),
    },
}))

const Image = styled('img')(({ theme }) => ({
    display: 'flex',
    height: '24px',
    width: '24px',
    borderRadius: '8px',

    [theme.breakpoints.up('phablet')]: {
        height: '40px',
        width: '40px',
    },
}))

const TitleTextView = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 700,

    [theme.breakpoints.up('phablet')]: {
        marginLeft: theme.spacing(2),
        fontSize: '18px',
    },
}))
