import { Box, CircularProgress, styled } from '@mui/material'

interface ActivityIndicatorProperties {
    isFullScreen?: boolean
    color?: string
}

export const ActivityIndicator = ({ isFullScreen, color }: ActivityIndicatorProperties) => {
    const Progress = () => (
        <CircularProgress
            sx={{
                margin: 'auto',
                color: color,
            }}
        />
    )

    if (isFullScreen) {
        return (
            <FullScreenContainer>
                <Progress />
            </FullScreenContainer>
        )
    }

    return (
        <Container>
            <Progress />
        </Container>
    )
}

const Container = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
}))

const FullScreenContainer = styled(Box)(() => ({
    width: '100%',
    height: '100vh',
    display: 'flex',
}))
