import { createContext, PropsWithChildren, useState } from 'react'
import { useTranslation } from '../../i18n'
import { MediaItem } from '../../models/MediaItem'
import { MediaFullscreen } from './MediaFullscreen'

export const FullScreenMediaContext = createContext<
    | {
          openFullscreenMedia(index: number, mediaItems: MediaItem[]): void
      }
    | undefined
>(undefined)

export const FullScreenMediaContextProvider = ({ children }: PropsWithChildren) => {
    const translations = useTranslation()
    const [fullscreenMediaIndex, setFullscreenMediaIndex] = useState<number | undefined>()
    const [mediaItems, setMediaItems] = useState<MediaItem[]>([])

    const openFullscreenMedia = (index: number, mediaItems: MediaItem[]) => {
        setFullscreenMediaIndex(index)
        setMediaItems(mediaItems)
    }

    const closeFullscreenMedia = () => {
        setFullscreenMediaIndex(undefined)
        setMediaItems([])
    }

    return (
        <FullScreenMediaContext.Provider
            value={{
                openFullscreenMedia,
            }}
        >
            {children}

            {fullscreenMediaIndex !== undefined && (
                <MediaFullscreen
                    mediaItems={mediaItems}
                    currentMediaIndex={fullscreenMediaIndex}
                    onClose={closeFullscreenMedia}
                    translations={translations}
                />
            )}
        </FullScreenMediaContext.Provider>
    )
}
