import { FileRepository } from '../files/FileRepository'
import { LocalImage } from '../forms/LocalImage'
import { LocalVideo } from '../forms/LocalVideo'
import { Image } from './Image'
import { Video } from './Video'

export enum MediaItemType {
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    PDF = 'PDF',
}

export class MediaItem {
    constructor(
        readonly type: MediaItemType,
        readonly id: string,
        readonly url: string,
        readonly videoUrl: string | undefined,
        readonly width: number,
        readonly height: number
    ) {}

    public static mapToMediaItems(
        items: {
            videos?: Video[]
            localVideos?: LocalVideo[]
            images?: Image[]
            localImages?: LocalImage[]
        },
        fileRepository
    ): MediaItem[] {
        const mediaItemVideos = items.videos?.map(
            (video) =>
                new MediaItem(
                    MediaItemType.VIDEO,
                    video.key,
                    fileRepository.getFileUrl(video.imageKey)!,
                    fileRepository.getFileUrl(video.key)!,
                    video.imageWidth,
                    video.imageHeight
                )
        )
        const mediaItemLocalVideos = items.localVideos?.map(
            (video) =>
                new MediaItem(
                    MediaItemType.VIDEO,
                    video.id,
                    video.thumbnail.source,
                    video.source,
                    video.thumbnail.width,
                    video.thumbnail.height
                )
        )
        const mediaItemImages = items.images?.map(
            (image) =>
                new MediaItem(
                    MediaItemType.IMAGE,
                    image.key,
                    fileRepository.getFileUrl(image.key)!,
                    undefined,
                    image.width,
                    image.height
                )
        )

        const mediaItemLocalImages = items.localImages?.map(
            (image) =>
                new MediaItem(
                    MediaItemType.IMAGE,
                    image.id,
                    image.source,
                    undefined,
                    image.width,
                    image.height
                )
        )

        return [
            ...(mediaItemLocalVideos ?? []),
            ...(mediaItemVideos ?? []),
            ...(mediaItemLocalImages ?? []),
            ...(mediaItemImages ?? []),
        ]
    }

    public static mapToMediaItem(image: Image, fileRepository: FileRepository): MediaItem[] {
        return [
            new MediaItem(
                MediaItemType.IMAGE,
                image.key,
                fileRepository.getFileUrl(image.key)!,
                undefined,
                image.width,
                image.height
            ),
        ]
    }
}
