import { CssBaseline, ThemeProvider } from '@mui/material'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ChurchApiClient } from './api/ChurchApiClient'
import { GivingApiClient } from './api/GivingApiClient'
import config from './config'
import i18n from './i18n'
import './index.css'
import { Main } from './main/Main'
import { Path } from './model/Path'
import { ChurchRepository } from './repositories/ChurchRepository'
import { GivingRepository } from './repositories/GivingRepository'
import theme from 'shared/lib/theme/Theme'
import { FileRepository } from 'shared/lib/files/FileRepository'

const churchApiClient = new ChurchApiClient(config.apiUrl)
const givingApiClient = new GivingApiClient(config.apiUrl)

export const churchRepository = new ChurchRepository(churchApiClient)
export const givingRepository = new GivingRepository(givingApiClient)
export const fileRepository = new FileRepository(config.cdnUrl)

const RedirectToDonkeyMobileWebsite = () => {
    window.location.href = 'https://donkeymobile.app'
    return <></>
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
            <CookiesProvider>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/">
                            <Route index element={<RedirectToDonkeyMobileWebsite />} />
                            <Route path={`${Path.APPLICATION_ID}/*`} element={<Main />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </CookiesProvider>
        </ThemeProvider>
    </I18nextProvider>
)
