import { SVGProps } from './SVGProps'
import { textColor } from '../../theme/Theme'

export const PlayIcon = ({ color = textColor }: SVGProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44" fill="none">
        <circle cx="22" cy="22" r="22" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 16.6843C18 15.5109 19.2867 14.7918 20.2861 15.4069L28.9241 20.7225C29.8759 21.3082 29.8759 22.6918 28.9241 23.2775L20.2861 28.5931C19.2867 29.2082 18 28.4891 18 27.3157V16.6843Z"
            fill={color}
        />
    </svg>
)
