import { TextField } from '@mui/material'
import { NumericFormat } from 'react-number-format'
import { grey_1, grey_2 } from 'shared/lib/theme/constants'

interface Properties {
    name: string
    value?: number
    onChange: (value?: number) => void
}

export function CharityAmountTextField(properties: Properties) {
    const { value, name, onChange, ...other } = properties
    return (
        <NumericFormat
            {...other}
            type="tel"
            value={value}
            name={name}
            customInput={TextField}
            autoComplete="off"
            onValueChange={({ floatValue }) => {
                onChange(floatValue)
            }}
            prefix="€ "
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            valueIsNumericString={true}
            allowNegative={false}
            variant="standard"
            sx={{
                '& .MuiInputBase-input': {
                    color: 'primary.main',
                },

                '& .MuiInputBase-root': {
                    fontSize: '18px',
                    fontWeight: 700,
                    '&:before': {
                        borderBottom: `1px solid ${grey_2}`,
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `1px solid ${grey_1}`,
                    },
                    '&:after': {
                        borderBottom: `1px solid ${grey_1}`,
                    },
                },

                input: {
                    border: '0px solid white',
                    textAlign: 'center',
                },
            }}
        />
    )
}
