import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './locales/en.json'
import translationNL from './locales/nl.json'
import translationDE from './locales/de.json'

i18n.use(LanguageDetector).init({
    detection: {
        order: [
            'navigator',
            'querystring',
            'cookie',
            'localStorage',
            'sessionStorage',
            'htmlTag',
            'path',
            'subdomain',
        ],
    },
    resources: {
        en: {
            translations: translationEN,
        },
        nl: {
            translations: translationNL,
        },
        de: {
            translations: translationDE,
        },
    },

    fallbackLng: ['en', 'nl', 'de'],
    debug: true,
    supportedLngs: ['en', 'nl', 'de'],
    load: 'languageOnly',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
    },
    react: {
        useSuspense: true,
    },
})

export default i18n
