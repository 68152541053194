import { SVGProps } from './SVGProps'

export const HamburgerMenuIcon = (properties: SVGProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill={properties.color}
        >
            <path
                d="M2.75 4.25C2.33579 4.25 2 4.58579 2 5C2 5.41421 2.33579 5.75 2.75 5.75H21.25C21.6642 5.75 22 5.41421 22 5C22 4.58579 21.6642 4.25 21.25 4.25H2.75Z"
                fill="current"
            />
            <path
                d="M2.75 11.25C2.33579 11.25 2 11.5858 2 12C2 12.4142 2.33579 12.75 2.75 12.75H21.25C21.6642 12.75 22 12.4142 22 12C22 11.5858 21.6642 11.25 21.25 11.25H2.75Z"
                fill="current"
            />
            <path
                d="M2 19C2 18.5858 2.33579 18.25 2.75 18.25H21.25C21.6642 18.25 22 18.5858 22 19C22 19.4142 21.6642 19.75 21.25 19.75H2.75C2.33579 19.75 2 19.4142 2 19Z"
                fill="current"
            />
        </svg>
    )
}
