import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import poweredByImageNL from '../assets/svg/img_made_with_love_nl.svg'
import poweredByImageEN from '../assets/svg/img_made_with_love_en.svg'

interface Properties {
    isMobile?: boolean
}

export const PoweredByImage = (properties: Properties) => {
    const language = useTranslation().i18n.language
    const poweredByImage = language.includes('nl') ? poweredByImageNL : poweredByImageEN
    const isMobile = properties.isMobile
    const marginTop = isMobile === undefined ? '24px' : isMobile ? '24px' : '48px'
    const mobile = isMobile === undefined ? '' : isMobile ? 'block' : 'none'
    const phablet = isMobile === undefined ? '' : isMobile ? 'none' : 'block'
    return (
        <Box height={40} mt={marginTop} textAlign="center" display={{ mobile, phablet }} mb={3}>
            <img src={poweredByImage} alt="Powered by Donkey Mobile" />
        </Box>
    )
}
