export const MAX_UPLOAD_SIZE_BYTES = 25000000
export const MAX_UPLOAD_SIZE_MB = MAX_UPLOAD_SIZE_BYTES / 1000000 // 25mb
export const MAX_PDF_SIZE_BYTES = 10000000 // 10mb

export const BYTES_PER_MB = 1000000
export const BYTES_PER_KB = 1000

export const DEFAULT_VALIDATION_TIMEOUT = 1000 // 1 seconds

export const DEFAULT_ANIMATION_DURATION = 300 // 300ms

export const DEFAULT_MAX_SESSION_AGE = 60 * 60
