import { Box, List as MuiList, styled } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'shared/lib/i18n'
import { grey_3 } from 'shared/lib/theme/constants'
import { ChurchTitleView } from '../../components/ChurchTitleView'
import { ContentContainer } from '../../components/ContentContainer'
import { PageTitleView } from '../../components/PageTitleView'
import { PoweredByImage } from '../../components/PoweredByImage'
import { DonateButton } from '../../components/buttons/DonateButton'
import { Church } from '../../model/Church'
import { PaymentMethod, PaymentMethodProvider, SelectedPaymentMethod } from './PaymentMethod'
import { PaymentMethodListItem } from './PaymentMethodListItem'

interface Properties {
    canContinue: boolean
    isStartingTransaction: boolean
    church: Church
    paymentMethods: PaymentMethod[]
    selectedPaymentMethod?: SelectedPaymentMethod
    paymentMethodsToShowAllProviders: Set<PaymentMethod>
    onShowAllProvidersClicked: (paymentMethod: PaymentMethod) => void
    onPaymentMethodSelected: (
        paymentMethod: PaymentMethod,
        provider?: PaymentMethodProvider
    ) => void
    onBackButtonClicked: () => void
    onContinueButtonClicked: () => void
}

export const PaymentMethodsView = ({
    paymentMethods,
    selectedPaymentMethod,
    ...properties
}: Properties) => {
    const translations = useTranslation()

    const onBackButtonClicked = () => {
        if (properties.isStartingTransaction) {
            return
        }

        properties.onBackButtonClicked()
    }

    const onPaymentMethodSelected = (
        paymentMethod: PaymentMethod,
        provider?: PaymentMethodProvider
    ) => {
        if (properties.isStartingTransaction) {
            return
        }

        properties.onPaymentMethodSelected(paymentMethod, provider)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Box mb={2}>
                <ChurchTitleView displayType="mobile_only" church={properties.church} />
                <PageTitleView
                    displayType="phablet_tablet_desktop_only"
                    title={translations('choose_payment_method')}
                    onBackButtonClicked={onBackButtonClicked}
                />

                <ContentContainer>
                    <PageTitleView
                        displayType="mobile_only"
                        backButtonColor="grey"
                        title={translations('choose_payment_method')}
                        onBackButtonClicked={onBackButtonClicked}
                    />

                    <List disablePadding={true}>
                        {paymentMethods.map((paymentMethod, index) => {
                            const nextPaymentMethod = paymentMethods[index + 1]
                            const providers = paymentMethod?.providers || []
                            const lastProvider = providers[providers.length - 1]

                            const isLastItem = index === paymentMethods.length - 1
                            const isSelected =
                                paymentMethod.isSelected(selectedPaymentMethod) ||
                                lastProvider?.isSelected(selectedPaymentMethod) === true
                            const isNextSelected =
                                nextPaymentMethod?.isSelected(selectedPaymentMethod) === true
                            const hasDivider = !isLastItem && !isSelected && !isNextSelected

                            const showAllProviders =
                                paymentMethods.length === 1 ||
                                properties.paymentMethodsToShowAllProviders.has(paymentMethod)

                            return (
                                <PaymentMethodListItem
                                    key={index}
                                    hasBottomDivider={hasDivider}
                                    showAllProviders={showAllProviders}
                                    paymentMethod={paymentMethod}
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    onShowMoreProvidersClicked={
                                        properties.onShowAllProvidersClicked
                                    }
                                    onPaymentMethodSelected={onPaymentMethodSelected}
                                />
                            )
                        })}
                    </List>
                </ContentContainer>
            </Box>

            <PoweredByImage isMobile={true} />

            <DonateButton
                isLoading={properties.isStartingTransaction}
                isEnabled={properties.canContinue}
                onClick={properties.onContinueButtonClicked}
            />

            <PoweredByImage isMobile={false} />
        </>
    )
}

const List = styled(MuiList)(({ theme }) => ({
    [theme.breakpoints.up('phablet')]: {
        padding: '2px',
    },

    [theme.breakpoints.down('phablet')]: {
        backgroundColor: grey_3,
        borderRadius: '24px',
        margin: theme.spacing(0, 3, 3),
    },
}))
