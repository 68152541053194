import { isAndroid } from 'mobile-device-detect'
import { useLocation } from 'react-router-dom'
import { Church } from '../model/Church'

export type Dictionary = { [key: string]: any }

export const CurrencyFormatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
})

export function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export function isUndefined(arg: any) {
    return typeof arg === 'undefined'
}

export function isUndefinedOrEmpty(value: string) {
    return value === undefined || value === ''
}

export function isNumber(value: any) {
    return typeof value === 'number' || value instanceof Number
}

export function merge(a: any, b: any) {
    return Object.assign({}, a, b)
}

export function getAppleOrAndroidAppStoreUrl(church: Church): string {
    const { appleAppTrackId, applicationId } = church
    if (appleAppTrackId === undefined || isAndroid) {
        return `https://play.google.com/store/apps/details?id=${applicationId}`
    } else {
        return `https://apps.apple.com/app/id${appleAppTrackId}`
    }
}
