import { Typography, styled } from '@mui/material'

interface Properties {
    translations: {
        readLess: string
    }
    paddingLeft?: any
    onClicked: () => void
}

export const ReadLessTextView = (properties: Properties) => {
    return (
        <TextView
            variant="body1"
            component="a"
            onClick={properties.onClicked}
            pl={properties.paddingLeft ?? 0.5}
        >
            {properties.translations.readLess}
        </TextView>
    )
}

const TextView = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main,
})) as typeof Typography
