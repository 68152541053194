import { TextField } from '@mui/material'
import { Ref } from 'react'
import { NumericFormat } from 'react-number-format'
import { darkGrey, grey_1, grey_2 } from 'shared/lib/theme/constants'

interface Properties {
    name: string
    inputRef?: Ref<any>
    value?: number
    onFocus?: () => void
    onBlur?: () => void
    onChange: (value?: number) => void
}

export const AmountTextField = (properties: Properties) => {
    const { value, name, onChange, ...other } = properties

    return (
        <NumericFormat
            {...other}
            type="tel"
            inputRef={properties.inputRef}
            value={value || ''}
            name={name}
            placeholder="0,00"
            fullWidth={true}
            customInput={TextField}
            autoComplete="off"
            onFocus={properties.onFocus}
            onBlur={properties.onBlur}
            onValueChange={({ floatValue }) => {
                onChange(floatValue)
            }}
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale={true}
            valueIsNumericString={true}
            allowNegative={false}
            variant="standard"
            sx={{
                '& .MuiInputBase-input': {
                    padding: 0,
                    color: darkGrey,

                    '::placeholder': {
                        color: grey_2,
                    },
                },

                '& .MuiInputBase-root': {
                    fontSize: '24px',
                    fontWeight: 800,
                    '&:before': {
                        color: grey_2,
                        borderBottom: `0px solid ${grey_2}`,
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `0px solid ${grey_1}`,
                    },
                    '&:after': {
                        borderBottom: `0px solid ${grey_1}`,
                    },
                },

                input: {
                    border: '0px solid white',
                    textAlign: 'right',
                },
            }}
        />
    )
}
