import { SVGProps } from './SVGProps'

export const ChevronLeftIcon = (properties: SVGProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={properties.color ?? 'current'}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.0303 3.46967C16.3232 3.76256 16.3232 4.23744 16.0303 4.53033L8.56066 12L16.0303 19.4697C16.3232 19.7626 16.3232 20.2374 16.0303 20.5303C15.7374 20.8232 15.2626 20.8232 14.9697 20.5303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697L14.9697 3.46967C15.2626 3.17678 15.7374 3.17678 16.0303 3.46967Z"
            fill={properties.color ?? 'current'}
        />
    </svg>
)
