import { Box, Button, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { grey_3 } from 'shared/lib/theme/constants'
import { fileRepository } from '../..'
import { Church } from '../../model/Church'
import { getAppleOrAndroidAppStoreUrl } from '../../utilities/Utils'

interface Properties {
    church: Church
}

export const DownloadAppView = (properties: Properties) => {
    const { t: translation } = useTranslation()
    const { church } = properties

    const navigateToAppStore = () => window.open(getAppleOrAndroidAppStoreUrl(church))

    return (
        <Container>
            <Box display="flex" flexDirection="row">
                <Box ml={2} mt={2} display="flex">
                    <ChurchImage
                        src={fileRepository.getThumbnailUrl(church.image)}
                        alt={church.name}
                    />
                </Box>

                <Box m={2} display="flex" flexDirection="column" flexGrow={1}>
                    <Typography variant="body1" fontSize="16px" fontWeight={600}>
                        {church.name}
                    </Typography>

                    <Typography variant="body2" fontSize="16px">
                        {translation('download_app_message')}
                    </Typography>

                    <Box mt={2} />

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            height: '40px',
                            color: 'white',
                        }}
                        onClick={navigateToAppStore}
                        fullWidth
                    >
                        {translation('download')}
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    background: grey_3,
    borderRadius: '24px',
}))

const ChurchImage = styled('img')(() => ({
    display: 'flex',
    height: '72px',
    width: '72px',
    borderRadius: '16px',
}))
