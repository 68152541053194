export class Charity {
    public static DEFAULT_CHARITY_NAME = 'DEFAULT_CHARITY'

    amount: number

    constructor(
        readonly id: string,
        readonly name: string,
        readonly description: string
    ) {
        this.amount = 4.0
    }

    static fromResponse(response: any): Charity {
        return new Charity(response.id, response.name, response.description)
    }
}
