import { Box, styled, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React, { memo } from 'react'
import { ImageSizeDeterminator } from './ImageSizeDeterminator'
import { ImageSizeType } from './ImageSizeType'
import { MediaItem } from '../../models/MediaItem'
import { defaultInnerDropShadow } from '../../theme/Theme'
import { FilledCloseButtonWithWhiteCross } from '../buttons/CloseButton'
import { PlayRoundedFilled } from '../../assets/svg/PlayRoundedFilled'

interface Properties {
    width: number
    mediaItems: MediaItem[]
    isDeleteButtonEnabled?: boolean
    onMediaItemSelected?: (index: number, mediaItems: MediaItem[]) => void
    onDeleteButtonClicked?: (mediaItem: MediaItem) => void
}

const Component = (properties: Properties) => {
    const items = properties.mediaItems
    const hasMoreItems = items.length > 4
    const isDeleteButtonEnabled = properties.isDeleteButtonEnabled ?? true

    if (items.length === 0) {
        return <></>
    }

    const getImageSizeType = (index: number) => {
        if (index === 0 && items.length === 1) return ImageSizeType.FULL
        return ImageSizeType.SQUARE
    }

    const getXS = (index: number) => {
        if (index === 0 && items.length !== 2) return 12
        if (index === 0 && items.length === 2) return 6
        if (index === 1 && items.length === 2) return 6
        if ([2, 3].includes(index) && items.length === 3) return 6
        if ([2, 3, 4].includes(index) && items.length > 3) return 4
        return 0
    }

    const getUrl = (index: number) => items[index].url
    const getIsVideo = (index: number) => items[index].type === 'VIDEO'

    const getHeight = (index: number) => {
        const item = items[index]
        const imageSizeType = getImageSizeType(index)
        switch (imageSizeType) {
            case ImageSizeType.FULL:
                return new ImageSizeDeterminator(item.width, item.height).proportionalHeightMax(
                    properties.width
                )
            case ImageSizeType.SQUARE:
                const firstRowHasOneImage = index === 0 && (items.length === 1 || items.length > 2)
                const spacing = firstRowHasOneImage ? 8 : 4
                const numberOfVisibleItemsInSecondRow = items.length > 3 ? 3 : 2
                const containerWidth = firstRowHasOneImage
                    ? properties.width
                    : properties.width / numberOfVisibleItemsInSecondRow
                return containerWidth + spacing
        }
    }

    const gridXS1 = getXS(0)
    const gridXS2 = getXS(1)
    const gridXS3 = getXS(2)
    const gridXS4 = getXS(3)
    const gridXS5 = getXS(4)

    const getDeleteButton = (index: number): React.JSX.Element => {
        return properties.onDeleteButtonClicked ? (
            <DeleteButtonContainer>
                <FilledCloseButtonWithWhiteCross
                    disabled={!isDeleteButtonEnabled}
                    onClick={(event) => {
                        event.stopPropagation()
                        properties.onDeleteButtonClicked!(items[index])
                    }}
                />
            </DeleteButtonContainer>
        ) : (
            <></>
        )
    }

    const onItemClicked = (index: number) => {
        properties.onMediaItemSelected?.(index, items)
    }

    return (
        <>
            <Grid container spacing={1}>
                {gridXS1 > 0 && (
                    <GridItem
                        mobile={gridXS1}
                        height={getHeight(0)}
                        onClick={() => onItemClicked(0)}
                    >
                        {getIsVideo(0) && <PlayVideoIcon />}
                        <ImageView src={getUrl(0)} />
                        <ImageViewDropShadow />
                        {getDeleteButton(0)}
                    </GridItem>
                )}
                {gridXS2 > 0 && (
                    <GridItem
                        mobile={gridXS2}
                        height={getHeight(1)}
                        onClick={() => onItemClicked(1)}
                    >
                        <ImageView src={getUrl(1)} />
                        <ImageViewDropShadow />
                        {getIsVideo(1) && <PlayVideoIcon />}
                        {getDeleteButton(1)}
                    </GridItem>
                )}
                {gridXS3 > 0 && (
                    <GridItem
                        mobile={gridXS3}
                        height={getHeight(1)}
                        onClick={() => onItemClicked(1)}
                    >
                        <ImageView src={getUrl(1)} />
                        <ImageViewDropShadow />
                        {getIsVideo(1) && <PlayVideoIcon />}
                        {getDeleteButton(1)}
                    </GridItem>
                )}
                {gridXS4 > 0 && (
                    <GridItem
                        mobile={gridXS4}
                        height={getHeight(2)}
                        onClick={() => onItemClicked(2)}
                    >
                        <ImageView src={getUrl(2)} />
                        <ImageViewDropShadow />
                        {getIsVideo(2) && <PlayVideoIcon />}
                        {getDeleteButton(2)}
                    </GridItem>
                )}
                {gridXS5 > 0 && (
                    <GridItem
                        mobile={gridXS5}
                        height={getHeight(3)}
                        onClick={() => onItemClicked(3)}
                    >
                        <ImageView src={getUrl(3)} />
                        {!hasMoreItems && <ImageViewDropShadow />}
                        {getIsVideo(3) && <PlayVideoIcon />}
                        {hasMoreItems ? (
                            <>
                                <MoreView />
                                <MoreTextView variant="body1">+{items.length - 4}</MoreTextView>
                            </>
                        ) : (
                            getDeleteButton(3)
                        )}
                    </GridItem>
                )}
            </Grid>
        </>
    )
}

export const MediaPreview = memo(Component, (previous, next) => {
    return (
        previous.width === next.width &&
        JSON.stringify(previous.mediaItems) === JSON.stringify(next.mediaItems)
    )
})

const PlayVideoIcon = () => {
    return (
        <PlayVideoIconContainer>
            <PlayRoundedFilled />
        </PlayVideoIconContainer>
    )
}

const GridItem = styled(Grid)(() => ({
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
}))

const ImageView = styled('img')(({ theme }) => ({
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(2),
    objectFit: 'cover',
}))

const ImageViewDropShadow = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(1)})`,
    width: `calc(100% - ${theme.spacing(1)})`,
    borderRadius: theme.spacing(2),
    top: 0,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    position: 'absolute',
    boxShadow: defaultInnerDropShadow,
}))

const PlayVideoIconContainer = styled(Box)(() => ({
    height: '44px',
    width: '44px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}))

const MoreView = styled(Box)(({ theme }) => ({
    height: `calc(100% - ${theme.spacing(1)})`,
    width: `calc(100% - ${theme.spacing(1)})`,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    backgroundColor: 'black',
    opacity: 0.5,
    position: 'absolute',
    top: 0,
    borderRadius: theme.spacing(2),
}))

const MoreTextView = styled(Typography)(() => ({
    position: 'absolute',
    color: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    lineHeight: 0,
    fontSize: '30px',
    fontWeight: 'bold',
}))

const DeleteButtonContainer = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
}))
