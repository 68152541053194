import { Fundraiser } from '../main/fundraiser/Fundraiser'
import { PaymentMethod } from '../main/paymentMethods/PaymentMethod'
import { Charity } from '../model/Charity'
import { Transaction } from '../model/Transaction'
import { CreateTransactionBody } from '../model/api/CreateTransactionBody'
import { ApiClient } from './ApiClient'

export class GivingApiClient extends ApiClient {
    async createTransaction(
        applicationId: string,
        transactions: CreateTransactionBody[]
    ): Promise<{ paymentUrl: string }> {
        const response = await this.post<{ paymentUrl: string }>(
            `${this.url}/api/v1/giving/transactions`,
            applicationId,
            {
                applicationId,
                transactions,
            }
        )
        return response
    }

    async getPaymentMethods(applicationId: string): Promise<PaymentMethod[]> {
        const response = await this.get<any[]>(
            `${this.url}/api/v1/giving/payment-methods`,
            applicationId
        )
        return response.map((paymentMethod) => PaymentMethod.fromResponse(paymentMethod))
    }

    async getCharities(applicationId: string): Promise<Charity[]> {
        const response = await this.get<any[]>(
            `${this.url}/api/v1/anonymous/giving/charities?applicationId=${applicationId}`,
            applicationId
        )

        return response.map((charity) => Charity.fromResponse(charity))
    }

    async getFundraiser(applicationId: string, fundraiserId: string): Promise<Fundraiser> {
        const response = await this.get(
            `${this.url}/api/v2/giving/fundraisers/${fundraiserId}`,
            applicationId
        )

        return Fundraiser.fromResponse(response)
    }

    async getTransaction(applicationId: string, transactionId: string) {
        return await this.get<Transaction>(
            `${this.url}/api/v1/giving/transactions/${transactionId}`,
            applicationId
        )
    }
}
