import { Box, ButtonBase, CircularProgress, styled, Typography } from '@mui/material'
import { useTranslation } from 'shared/lib/i18n'
import { grey_1, grey_3 } from 'shared/lib/theme/constants'
import { ReactComponent as ArrowRight } from './../../assets/svg/ic_arrow_right.svg'

interface Properties {
    isLoading: boolean
    isEnabled: boolean
    title?: string
    onClick: () => void
}

export const DonateButton = ({ isLoading, isEnabled, ...properties }: Properties) => {
    const translations = useTranslation()

    return (
        <>
            <Container>
                <ContainerButton
                    id="paymentButton"
                    disabled={!isEnabled}
                    onClick={properties.onClick}
                    sx={{
                        pointerEvents: isLoading ? 'none' : 'auto',
                    }}
                >
                    <ButtonContainer>
                        <Box display="flex" flexGrow={1} mr={2}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                textAlign="right"
                                width="100%"
                            >
                                <Typography variant="body1" color="white" fontSize="24px">
                                    <strong>{properties.title ?? translations('give')}</strong>
                                </Typography>
                            </Box>
                        </Box>

                        <ArrowContainer>
                            {isLoading && (
                                <CircularProgress
                                    sx={{
                                        color: 'white',
                                    }}
                                    size="20px"
                                />
                            )}
                            {!isLoading && <ArrowRight />}
                        </ArrowContainer>
                    </ButtonContainer>
                </ContainerButton>
            </Container>

            <Placeholder />
        </>
    )
}

const containerPadding = 16
const buttonInnerHeight = 56
const buttonPaddingY = 6

const Container = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down('phablet')]: {
        position: 'fixed',
        padding: containerPadding,
        left: 0,
        bottom: 0,
        width: '100%',
    },
}))

const ContainerButton = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    paddingTop: buttonPaddingY,
    paddingBottom: buttonPaddingY,
    borderRadius: '16px',
    textTransform: 'none',
    boxShadow: 'none',
    paddingRight: '0px',
    paddingLeft: theme.spacing(5),

    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        boxShadow:
            'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
    },

    '&:disabled': {
        backgroundColor: grey_1,
        color: grey_3,
    },
}))

const ButtonContainer = styled(Box)(() => ({
    height: buttonInnerHeight,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
}))

const ArrowContainer = styled(Box)(() => ({
    display: 'flex',
    height: '40px',
    width: '40px',
    backgroundColor: '#FFFFFF4A',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
}))

const Placeholder = styled(Box)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.down('phablet')]: {
        display: 'block',
        height: containerPadding + buttonPaddingY * 2 + buttonInnerHeight,
    },
}))
