import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { BackButton } from './buttons/BackButton'

export const PageTitleView = (properties: {
    displayType: 'mobile_only' | 'phablet_tablet_desktop_only' | 'all'
    backButtonColor?: 'white' | 'grey'
    title?: string
    onBackButtonClicked: () => void
}) => {
    const isMobileOnly = properties.displayType === 'mobile_only'
    const isPhabletTabletDestkopOnly = properties.displayType === 'phablet_tablet_desktop_only'
    const isAll = properties.displayType === 'all'
    const backButtonColor = properties.backButtonColor || 'white'
    return (
        <Container
            display={{
                mobile: isAll || isMobileOnly ? 'flex' : 'none',
                phablet: isAll || isPhabletTabletDestkopOnly ? 'flex' : 'none',
                tablet: isAll || isPhabletTabletDestkopOnly ? 'flex' : 'none',
                desktop: isAll || isPhabletTabletDestkopOnly ? 'flex' : 'none',
            }}
        >
            <BackButton color={backButtonColor} onClick={properties.onBackButtonClicked} />
            {properties.title && <TitleTextView variant="h3">{properties.title}</TitleTextView>}
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(3),

    [theme.breakpoints.up('phablet')]: {
        justifyContent: 'flex-start',
        paddingLeft: 0,
        marginTop: theme.spacing(4),
    },
}))

const TitleTextView = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(3),
    fontSize: '18px',
    fontWeight: 700,

    [theme.breakpoints.up('phablet')]: {
        fontSize: '20px',
    },
}))
