import { ChurchApiClient } from '../api/ChurchApiClient'
import { Church } from '../model/Church'

export class ChurchRepository {
    constructor(private readonly churchApiClient: ChurchApiClient) {}

    async getChurch(applicationId: string): Promise<Church> {
        return this.churchApiClient.getChurch(applicationId)
    }
}
