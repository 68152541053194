import { IconButton as MuiIconButton, styled } from '@mui/material'
import { IconButtonProperties } from './DefaultButton'
import { darkGrey } from '../../theme/Theme'
import { CancelWhiteIcon, CloseIcon } from '../../assets/svg'

export const CloseButton = (properties: IconButtonProperties) => (
    <IconButton size={properties.buttonSize} onClick={properties.onClick}>
        <CloseIcon color={properties.color || '#445058'} />
    </IconButton>
)

export const FilledCloseButtonWithWhiteCross = (properties: IconButtonProperties) => (
    <IconButton
        disabled={properties.disabled}
        size={properties.buttonSize}
        onClick={properties.onClick}
        opacity={properties.opacity}
        edge={properties.edge}
    >
        <CancelWhiteIcon color={properties.color || darkGrey} />
    </IconButton>
)

const IconButton = styled(MuiIconButton)<{ opacity?: number }>(({ opacity }) => ({
    opacity: opacity ?? 1,
    height: '40px',
    width: '40px',

    '& svg': {
        width: '32px',
        height: '32px',
    },
}))
