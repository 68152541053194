import { Box, BoxProps, styled } from '@mui/material'
import { cardContentShadow } from 'shared/lib/theme/constants'

interface ContentContainerProps {
    children: React.ReactNode
    hasBottomBorderRadiusOnMobile?: boolean
    hasStaticHeight?: boolean
    backgroundColor?: string
    bottomThreshold?: number
    onScroll?: (hasReachedBottom: boolean) => void
}

export const ContentContainer = (properties: ContentContainerProps) => {
    const hasBottomBorderRadiusOnMobile = properties.hasBottomBorderRadiusOnMobile ?? true
    const hasStaticHeight = properties.hasStaticHeight ?? true
    const bottomThreshold = properties.bottomThreshold || 0

    const onScroll = (element: HTMLElement) => {
        const scrolledY = element.scrollHeight - element.scrollTop - bottomThreshold
        const hasReachBottom = scrolledY <= element.clientHeight
        if (properties.onScroll) properties.onScroll(hasReachBottom)
    }

    return (
        <>
            <Container
                id="contentContainer"
                hasBottomBorderRadiusOnMobile={hasBottomBorderRadiusOnMobile}
                hasStaticHeight={hasStaticHeight}
                bgcolor={properties.backgroundColor || 'white'}
                onScroll={(event) => onScroll(event.currentTarget)}
            >
                {properties.children}
                <Box id="hiddenBottom" />
            </Container>
        </>
    )
}

const Container = styled(
    ({
        hasBottomBorderRadiusOnMobile,
        hasStaticHeight,
        ...properties
    }: BoxProps & { hasBottomBorderRadiusOnMobile: boolean; hasStaticHeight: boolean }) => (
        <Box {...properties} />
    )
)(({ theme, hasBottomBorderRadiusOnMobile, hasStaticHeight }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: hasBottomBorderRadiusOnMobile ? '24px' : '24px 24px 0 0',

    [theme.breakpoints.only('mobile')]: {
        minHeight: '30vh',
    },

    [theme.breakpoints.up('phablet')]: {
        borderRadius: '24px',
        height: hasStaticHeight ? '50vh' : 'auto',
        overflow: 'auto',
        boxShadow: cardContentShadow,
    },
}))
