export default class Config {
    static get apiUrl() {
        if (process.env.REACT_APP_API_URL) {
            return process.env.REACT_APP_API_URL
        }

        if (window.location.host.includes('amplify')) {
            return 'https://testing.donkeymobile.com'
        }

        switch (window.location.host) {
            case 'giving-testing.donkeymobile.com':
                return 'https://testing.donkeymobile.com'
            case 'giving-staging.donkeymobile.com':
                return 'https://staging.donkeymobile.com'
            case 'giving.donkeymobile.com':
            case 'give.donkeymobile.com':
            default:
                return 'https://donkeymobile.com'
        }
    }

    static get cdnUrl() {
        if (process.env.REACT_APP_CDN_URL) {
            return process.env.REACT_APP_CDN_URL
        }

        if (window.location.host.includes('amplify')) {
            return 'https://cdn-testing.donkeymobile.com'
        }

        switch (window.location.host) {
            case 'giving-testing.donkeymobile.com':
                return 'https://cdn-testing.donkeymobile.com'
            case 'giving-staging.donkeymobile.com':
                return 'https://cdn-staging.donkeymobile.com'
            case 'giving.donkeymobile.com':
            case 'give.donkeymobile.com':
            default:
                return 'https://cdn.donkeymobile.com'
        }
    }
}
