import { TranslationFn } from '../WithTranslations'

export enum ErrorCode {
    REVOKED_ACCESS_TOKEN = 2003,
    REVOKED_REFRESH_TOKEN = 2004,
    INVALID_ACCESS_TOKEN = 2007,
    INVALID_MFA_SESSION = 2010,
    INVALID_SCIPIO_CREDENTIALS = 2011,
    PERMISSION_DENIED_MFA_NOT_ENABLE = 3001,
    PERMISSION_DENIED = 3000,
    FEED_NOT_FOUND = 4029,
    SLUG_NOT_FOUND = 4034,
    FEED_ITEM_NOT_FOUND = 4032,
    FEED_URL_TIMEOUT = 4800,
    FEED_NOT_ACCESSIBLE = 3006,
    DUPLICATE_STORE_NAME = 1055,
}

export enum BadRequestError {
    INVALID_EMAIL_ADDRESS = 'INVALID_EMAIL_ADDRESS',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
}

export const getStatusCode = (error: any): number => {
    try {
        return error && error.response
            ? error.response.status
            : error && error.message
              ? JSON.parse(error.message).status
              : -1
    } catch {
        return -1
    }
}

export const getErrorCode = (error: any): number => {
    try {
        return error && error.response && error.response.data
            ? error.response.data.code
            : error && error.message
              ? JSON.parse(error.message).code
              : -1
    } catch {
        return -1
    }
}

export const getErrorMessage = (properties: {
    error?: any
    badRequestError?: BadRequestError
    locale?: string
    translations?: TranslationFn
}): string | undefined => {
    if (properties.error === undefined || properties.error?.name === 'CanceledError') {
        return undefined
    }

    const translations = properties.translations
    const errorCode = getErrorCode(properties.error)
    const isDutch = properties.locale === 'nl'
    const defaultMessage =
        translations?.('message_something_went_wrong') ??
        (isDutch
            ? 'Er is iets misgegaan. Probeer het later opnieuw.'
            : 'Something went wrong. Please try again later.')

    switch (errorCode) {
        case 1002:
            switch (properties.badRequestError) {
                case BadRequestError.INVALID_EMAIL_ADDRESS:
                    return (
                        translations?.('invalid_email_address') ??
                        (isDutch ? 'Ongeldige e-mailadres' : 'Invalid email address.')
                    )
                default:
                    return defaultMessage
            }
        case 1005:
            return (
                translations?.('error_email_already_used') ??
                (isDutch ? 'Er is al een gebruiker met dit e-mailadres' : 'Email already used.')
            )
        case 1006:
            return (
                translations?.('file_too_large_message') ??
                (isDutch ? 'Bestand is te groot' : 'File too large.')
            )
        case 1007:
            return (
                translations?.('select_multiple_media_limit_exceeded_message', [25]) ??
                (isDutch ? 'Selecteer maximaal 25 media items' : 'Select maximum 25 media items.')
            )
        case 1009:
            return (
                translations?.('message_invalid_confirmation_code') ??
                (isDutch ? 'De bevestigingscode is ongeldig' : 'Invalid confirmation code.')
            )
        case 1010:
            return (
                translations?.('message_expired_confirmation_code') ??
                (isDutch ? 'Deze code is verlopen.' : 'This code is expired.')
            )
        case 1012:
            return (
                translations?.('email_address_already_registered') ??
                (isDutch
                    ? 'Dit e-mailadres is al in gebruik.'
                    : 'Email address already registered.')
            )
        case 1014:
            return (
                translations?.('message_attempt_limit_exceeded') ??
                (isDutch
                    ? 'Poginglimiet overschreden. Probeer het na enige tijd opnieuw.'
                    : 'Attempt limit exceeded. Please try again later.')
            )
        case 1022:
            return (
                translations?.('error_giving_not_enabled') ??
                (isDutch ? 'Geven is niet ingesteld.' : 'Giving not enabled.')
            )
        case 1039:
            return (
                translations?.('error_year_not_active_in_fund') ??
                (isDutch
                    ? 'De opgegeven incasso looptijd wordt niet ondersteund in het gekoppelde LRP fonds. Activeer het jaar waarin deze looptijd valt in LRP en probeer opnieuw.'
                    : 'The given direct debit time period is not supported by the connected LRP fund. Please activate the year of this time period in LRP and try again.')
            )
        case 1046:
            return (
                translations?.('duplicate_feed_message') ??
                (isDutch ? 'Er is al een feed met dezelfde url.' : 'Feed url already exists.')
            )
        case 1050:
            return (
                translations?.('partner_account_already_exists') ??
                (isDutch
                    ? 'Er is al een partneraccount met het opgegeven e-mailadres geregistreerd.'
                    : 'There is already a partner account with the given email address registered.')
            )
        case 1054:
            return (
                translations?.('feed_without_items') ??
                (isDutch ? 'The feed has no items.' : 'Feed without items.')
            )
        case 1055:
            return (
                translations?.('duplicate_store_name') ??
                (isDutch
                    ? 'Er is al een feed met deze winkelnaam.'
                    : 'There is already a feed with this store name.')
            )
        case 2001:
            switch (properties.badRequestError) {
                case BadRequestError.INVALID_PASSWORD:
                    return (
                        translations?.('message_invalid_current_password') ??
                        (isDutch ? 'Onjuist huidige wachtwoord.' : 'Invalid current password.')
                    )
                default:
                    return (
                        translations?.('message_invalid_username_or_password') ??
                        (isDutch
                            ? 'Ongeldig e-mailadres of wachtwoord.'
                            : 'Invalid username or password.')
                    )
            }
        case 2010:
            return (
                translations?.('error_invalid_mfa_session') ??
                (isDutch ? 'Je sessie is verlopen. Log opnieuw in' : 'Invalid MFA session.')
            )
        case 2012:
            return (
                translations?.('temporary_password_expired') ??
                (isDutch ? 'Je tijdelijk wachtwoord is verlopen.' : 'Temporary password expired.')
            )
        case ErrorCode.INVALID_SCIPIO_CREDENTIALS:
            return (
                translations?.('invalid_credentials') ??
                (isDutch
                    ? 'De ingevulde inloggegevens zijn onjuist.'
                    : 'The provided credentials are invalid.')
            )
        case ErrorCode.PERMISSION_DENIED:
            return (
                translations?.('message_permission_denied') ??
                (isDutch ? 'Toegang geweigerd.' : 'Permission denied.')
            )
        case 3006:
            return (
                translations?.('feed_not_accessible') ??
                (isDutch ? 'Geen toegang tot de feed url.' : 'Not allowed to read the feed URL.')
            )
        case 4005:
            return (
                translations?.('group_not_found_message') ??
                (isDutch ? 'Groep niet gevonden.' : 'Group not found.')
            )
        case 4006:
            return (
                translations?.('error_not_found_group_member') ??
                (isDutch ? 'Groepslid niet gevonden.' : 'Group member not found.')
            )
        case 4007:
            return (
                translations?.('post_not_found_message') ??
                (isDutch ? 'Bericht niet gevonden.' : 'Post not found.')
            )
        case 4009:
            return (
                translations?.('error_not_found_charity') ??
                (isDutch ? 'Collecte niet gevonden.' : 'Charity not found.')
            )
        case 4010:
            return (
                translations?.('error_not_found_fundraiser') ??
                (isDutch ? 'Inzamelingsactie niet gevonden.' : 'Fundraiser not found.')
            )
        case 4012:
            return (
                translations?.('error_not_found_file') ??
                (isDutch ? 'Bestand niet gevonden.' : 'File not found.')
            )
        case 4013:
            return (
                translations?.('event_not_found_message') ??
                (isDutch ? 'Activiteit niet gevonden.' : 'Event not found.')
            )
        case 4014:
            return (
                translations?.('message_user_not_found') ??
                (isDutch ? 'Gebruiker niet gevonden' : 'User not found.')
            )
        case 4024:
            return (
                translations?.('lrpNumberAlreadyUsed') ??
                (isDutch ? 'LRP nummer is al in gebruiker.' : 'LRP number already used.')
            )
        case ErrorCode.FEED_NOT_FOUND:
            return (
                translations?.('project_not_found') ??
                (isDutch ? 'Project niet gevonden' : 'Project not found.')
            )
        case ErrorCode.FEED_ITEM_NOT_FOUND:
            return (
                translations?.('project_item_not_found') ??
                (isDutch ? 'Project item niet gevonden.' : 'Project item not found.')
            )
        case 4033:
            return (
                translations?.('partner_account_not_found') ??
                (isDutch ? 'Partner account niet gevonden.' : 'Partner account not found.')
            )
        case 5008:
            return (
                translations?.('subscribing_to_feed_succeeded_with_errors') ??
                (isDutch
                    ? 'Abonneren op feed is gelukt met fouten.'
                    : 'Subscribing to feed succeeded with errors.')
            )
        default:
            return defaultMessage
    }
}
