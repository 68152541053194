import { ChurchTitleView } from '../../components/ChurchTitleView'
import { ContentContainer } from '../../components/ContentContainer'
import { PageTitleView } from '../../components/PageTitleView'
import { Church } from '../../model/Church'
import { DonationFailedView } from './DonationFailedView'
import { DonationSuccessView } from './DonationSuccessView'
import { DonkeyBrandingView } from './DonkeyBrandingView'
import { DownloadAppView } from './DownloadAppView'

interface Properties {
    church: Church
    amount: number
    isPaid: boolean
    onBackButtonClicked: () => void
    onTryAgainButtonClicked: () => void
}

export const CallbackFromBankView = (properties: Properties) => {
    const { church, amount, isPaid } = properties

    return (
        <>
            <ChurchTitleView displayType="mobile_only" church={properties.church} />
            <PageTitleView
                displayType="phablet_tablet_desktop_only"
                onBackButtonClicked={properties.onBackButtonClicked}
            />

            <ContentContainer hasBottomBorderRadiusOnMobile={false} hasStaticHeight={false}>
                <PageTitleView
                    displayType="mobile_only"
                    backButtonColor="grey"
                    onBackButtonClicked={properties.onBackButtonClicked}
                />

                {isPaid && <DonationSuccessView amount={amount} />}
                {!isPaid && (
                    <DonationFailedView
                        onTryAgainButtonClicked={properties.onTryAgainButtonClicked}
                    />
                )}

                {church && isPaid && <DownloadAppView church={church} />}

                {isPaid && <DonkeyBrandingView isMobile={true} />}
            </ContentContainer>

            {isPaid && <DonkeyBrandingView isMobile={false} />}
        </>
    )
}
