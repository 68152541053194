import { ReactComponent as ChevronDownIcon } from '../../assets/svg/chevron_down.svg'
import { Box, Fab, styled, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DonateButton } from '../../components/buttons/DonateButton'
import { ContentContainer } from '../../components/ContentContainer'
import { PoweredByImage } from '../../components/PoweredByImage'
import { Charity } from '../../model/Charity'
import { Church } from '../../model/Church'
import { CharityView } from './CharityView'
import { cardContentShadow, grey_3 } from 'shared/lib/theme/constants'
import { fileRepository } from '../..'
import { getAppleOrAndroidAppStoreUrl } from '../../utilities/Utils'

interface Properties {
    canContinue: boolean
    church: Church
    charities: Charity[]
    onCharityAmountChanged: (charityId: string, amount: number) => void
    onContinueButtonClicked: () => void
}

export const CharitiesView = (properties: Properties) => {
    const { t: translation } = useTranslation()
    const { church, charities } = properties

    const bottomThreshold = 50
    const [isMoreButtonVisible, setIsMoreButtonVisible] = useState(false)

    const isScrollable = () => {
        const contentContainer = document.getElementById('contentContainer')
        const contentContainerBottom = contentContainer?.getBoundingClientRect()?.bottom || 0
        const paymentButton = document.getElementById('paymentButton')
        const paymentButtonTop =
            (paymentButton?.getBoundingClientRect()?.top || 0) + bottomThreshold

        return contentContainer
            ? contentContainer.scrollHeight - bottomThreshold > contentContainer.clientHeight ||
                  contentContainerBottom > paymentButtonTop
            : false
    }

    const onScroll = (hasReachedBottom: boolean) => {
        setIsMoreButtonVisible(!hasReachedBottom)
    }

    const scrollToBottom = () => {
        const div = document.getElementById('hiddenBottom')
        div?.scrollIntoView({ behavior: 'smooth' })
    }

    const MoreButtonContent = () => (
        <>
            <Box mr={1}>
                <Typography variant="body1" color="primary">
                    {translation('more') || ''}
                </Typography>
            </Box>
            <ChevronDown />
        </>
    )

    const updateMoreButton = useCallback(() => {
        setIsMoreButtonVisible(isScrollable())
    }, [])

    useEffect(() => {
        updateMoreButton()
    }, [charities, updateMoreButton])

    useEffect(() => {
        window.addEventListener('resize', updateMoreButton)
        window.addEventListener('scroll', updateMoreButton)
        return () => {
            window.removeEventListener('resize', updateMoreButton)
            window.removeEventListener('scroll', updateMoreButton)
        }
    }, [updateMoreButton])

    return (
        <Container>
            <Box display="flex" justifyContent="center">
                <ChurchContainer onClick={() => window.open(getAppleOrAndroidAppStoreUrl(church))}>
                    <ChurchImageView
                        src={fileRepository.getThumbnailUrl(church.image)}
                        alt={church.name}
                    />
                    <ChurchNameTextView variant="h3">{church.name}</ChurchNameTextView>
                </ChurchContainer>
            </Box>

            <BackgroundGradientContainer>
                <ContentContainer bottomThreshold={bottomThreshold} onScroll={onScroll}>
                    {charities.map((charity, index) => (
                        <CharityView
                            key={index}
                            charity={charity}
                            onAmountChanged={properties.onCharityAmountChanged}
                        />
                    ))}
                </ContentContainer>
            </BackgroundGradientContainer>

            {isMoreButtonVisible && (
                <Box display={{ mobile: 'none', phablet: 'block' }}>
                    <MoreButtonFab variant="extended" size="medium" onClick={scrollToBottom}>
                        <MoreButtonContent />
                    </MoreButtonFab>
                </Box>
            )}

            <PoweredByImage isMobile={true} />

            {isMoreButtonVisible && (
                <Box display={{ mobile: 'block', phablet: 'none' }}>
                    <MoreButtonMobileFab variant="extended" size="medium" onClick={scrollToBottom}>
                        <MoreButtonContent />
                    </MoreButtonMobileFab>
                </Box>
            )}

            <DonateButton
                isLoading={false}
                isEnabled={properties.canContinue}
                onClick={properties.onContinueButtonClicked}
            />

            <PoweredByImage isMobile={false} />
        </Container>
    )
}

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
}))

const ChurchContainer = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: 'white',
    borderRadius: '24px',
    boxShadow: cardContentShadow,

    padding: theme.spacing(1, 2, 1, 1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('phablet')]: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(3),
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
}))

const ChurchImageView = styled('img')(({ theme }) => ({
    display: 'flex',
    height: '24px',
    width: '24px',
    borderRadius: '8px',
    marginRight: theme.spacing(1),

    [theme.breakpoints.up('phablet')]: {
        height: '40px',
        width: '40px',
        marginRight: theme.spacing(2),
    },
}))

const ChurchNameTextView = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontSize: '14px',
    fontWeight: 700,

    [theme.breakpoints.up('phablet')]: {
        fontSize: '20px',
    },
}))

const BackgroundGradientContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2),

    [theme.breakpoints.only('mobile')]: {
        padding: theme.spacing(3, 2, 2),
        borderTopLeftRadius: '31px',
        borderTopRightRadius: '31px',
        backgroundImage: `linear-gradient(to bottom, rgba(251, 251, 251, 0.48), ${grey_3} 8%)`,
    },
}))

const MoreButtonMobileFab = styled(Fab)(({ theme }) => ({
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    textTransform: 'none',

    [theme.breakpoints.down('tablet')]: {
        position: 'fixed',
        bottom: '100px',
        left: '32px',
    },
}))

const MoreButtonFab = styled(Fab)(({ theme }) => ({
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    textTransform: 'none',

    [theme.breakpoints.up('mobile')]: {
        display: 'flex',
        position: 'absolute',
        textAlign: 'center',
        bottom: '16px',
        left: '16px',
    },
}))

const ChevronDown = styled(ChevronDownIcon)(({ theme }) => ({
    width: '20px',
    height: '20px',
    fill: theme.palette.primary.main,
}))
