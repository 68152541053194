import { SVGProps } from 'react'

export const DragIcon = (props: SVGProps<any>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="current"
        {...props}
    >
        <path
            d="M4.6 8C4.26863 8 4 8.33579 4 8.75C4 9.16421 4.26863 9.5 4.6 9.5H19.4C19.7314 9.5 20 9.16421 20 8.75C20 8.33579 19.7314 8 19.4 8H4.6Z"
            fill="current"
        />
        <path
            d="M4.6 14.5C4.26863 14.5 4 14.8358 4 15.25C4 15.6642 4.26863 16 4.6 16H19.4C19.7314 16 20 15.6642 20 15.25C20 14.8358 19.7314 14.5 19.4 14.5H4.6Z"
            fill="current"
        />
    </svg>
)
